import { Form, InputEmail, InputHidden } from '@/Form';
import { ErrorPage } from '@/Layout/ErrorPage';
import { IonList } from '@ionic/react';
import { useCallback } from 'react';
import { useStores } from 'stores';

export const ErrorOptedOut: React.FC = () => {
    const { authStore, routerStore } = useStores();

    const handleSubmit = useCallback(({ email }: { email: string }) => authStore.optInParticipant(email), [authStore]);

    const email = routerStore.query['email'];

    return (
        <ErrorPage message="We noticed you are unsubscribed.">
            <div style={{ maxWidth: '700px' }}>
                Due to technical limitations we cannot support user accounts that are unsubscribed from study announcement emails. To continue to the URI
                Participant Portal, please resubscribe.
            </div>
            <Form handleSubmit={handleSubmit} submitButton="Resubscribe" style={{ width: '100%' }}>
                <IonList>{email ? <InputHidden name="email" defaultValue={email} /> : <InputEmail name="email" />}</IonList>
            </Form>
        </ErrorPage>
    );
};
