import { FieldValues, Path } from 'react-hook-form';
import { FieldProps } from './Field';
import { Input, InputProps } from './Input';
import { InputEmail, InputEmailProps } from './InputEmail';
import { InputPassword, InputPasswordProps } from './InputPassword';
import { CheckboxGroup, CheckboxGroupProps } from './CheckboxGroup';

export type FieldTypeProps<T, V extends FieldValues> = Omit<T, 'name'> & Omit<FieldProps<V>, 'value' | 'name' | 'render'>;

export type InputField<V extends FieldValues> = { fieldType: 'input' } & FieldTypeProps<InputProps<V>, V>;
export type InputEmailField<V extends FieldValues> = { fieldType: 'email' } & FieldTypeProps<InputEmailProps<V>, V>;
export type InputPasswordField<V extends FieldValues> = { fieldType: 'password' } & FieldTypeProps<InputPasswordProps<V>, V>;
export type CheckboxGroupField<V extends FieldValues> = { fieldType: 'checkbox-group' } & FieldTypeProps<CheckboxGroupProps<V>, V>;

export type FieldsDef<V extends FieldValues> = Record<keyof V, InputField<V> | InputEmailField<V> | InputPasswordField<V> | CheckboxGroupField<V>>;

export interface FieldsProps<V extends FieldValues> {
    fields: FieldsDef<V>;
    defaultProps?: Partial<Omit<FieldProps<V>, 'value' | 'name' | 'render'>>;
}

export function Fields<V extends FieldValues>({ fields, defaultProps = {} }: FieldsProps<V>): JSX.Element {
    return (
        <>
            {Object.keys(fields).map((fieldName) => {
                const { fieldType, ...fieldProps } = fields[fieldName];

                const props = { key: fieldName, name: fieldName as Path<V>, ...defaultProps, ...fieldProps };

                switch (fieldType) {
                    case 'input':
                        return <Input {...(props as InputProps<V>)} />;
                    case 'email':
                        return <InputEmail {...(props as InputEmailProps<V>)} />;
                    case 'password':
                        return <InputPassword {...(props as InputPasswordProps<V>)} />;
                    case 'checkbox-group':
                        return <CheckboxGroup {...(props as CheckboxGroupProps<V>)} />;
                }
            })}
        </>
    );
}
