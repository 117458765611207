import { AxiosError } from 'axios';

export class ApiError extends Error {
    readonly statusCode: number;
    readonly errorCode?: string;
    readonly message: string;

    handled: boolean;

    constructor(error: { statusCode: number | string; message: string; errorCode?: string }, handled = false) {
        super();

        this.statusCode = Number(error.statusCode);
        this.errorCode = error.errorCode;
        this.message = error.message;

        this.handled = handled;
    }
}

export const isApiError = (error: AxiosError<unknown>): error is AxiosError<{ statusCode: number | string; message: string; errorCode?: string }> => {
    return (
        !!error.response?.data && typeof error.response?.data === 'object' && typeof (error.response?.data as Record<string, unknown>).statusCode === 'number'
    );
};
