import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { config } from '../../config';
import { ApiError, isApiError } from './ApiError';
import { ValidationError, isValidationError } from './ValidationError';
import { isPojo } from 'utils';
import { ApiName, QueuedRequest, RequestInterceptor, Request, RetryInterceptor } from './types';

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)Z?$/;

const apiConfigs = Object.freeze<Record<ApiName, AxiosRequestConfig>>({
    auth: {
        baseURL: config.authApiBaseUrl,
        withCredentials: true,
    },
    project: {
        baseURL: config.projectApiBaseUrl,
    },
    qualtrics: {
        baseURL: config.qualtricsApiBaseUrl,
    },
    analytics: {
        baseURL: config.analyticsApiBaseUrl,
    },
    communication: {
        baseURL: config.communicationApiBaseUrl,
    },
});

export class ApiService {
    readonly apis: Record<ApiName, AxiosInstance>;

    private retryReasons: string[] = [];
    private requestQueue: QueuedRequest[] = [];
    private requestInterceptors: RequestInterceptor[] = [];
    private retryInterceptors: RetryInterceptor[] = [];

    constructor() {
        this.apis = Object.keys(apiConfigs).reduce(
            (apis, apiName) => ({ ...apis, [apiName]: this.createApi(apiName as ApiName) }),
            {} as Record<ApiName, AxiosInstance>
        );
    }

    createApi(apiName: ApiName): AxiosInstance {
        const api = axios.create(apiConfigs[apiName]);

        api.interceptors.request.use(
            async (req) => {
                if (!req.headers) {
                    req.headers = {};
                }

                const request = req as Request;

                request.id = `[${request.method}] ${request.baseURL}/${request.url}`;

                if (request.headers.__allowWhilePaused) {
                    delete request.headers.__allowWhilePaused;
                    request.allowWhilePaused = true;
                }

                if (this.retryReasons.length && !request.allowWhilePaused) {
                    // we await this promise since we don't want to process this
                    // request any further until requests are resumed
                    await this.queueRequest(request, apiName);
                }

                for (const requestInterceptor of this.requestInterceptors) {
                    await requestInterceptor.interceptor(request, apiName);
                }

                return request;
            },
            async (error: AxiosError<unknown>) => {
                console.log('api request error', error);
            }
        );

        api.interceptors.response.use(
            (resp) => {
                if (isPojo(resp)) {
                    this.transformResponse(resp);
                }
                return resp;
            },
            async (error: AxiosError<unknown>) => {
                const request = error.config as Request | undefined;

                if (request) {
                    for (const retryInterceptor of this.retryInterceptors) {
                        const { shouldRetry, beforeRetry } = retryInterceptor;
                        const retryReason = shouldRetry(error, request, apiName);
                        if (retryReason) {
                            // if we are already paused due do a retry of this type, then we should just
                            // queue this request but we'll push it to the top of the stack since it technically happened
                            // before any other requests that were queued after the pause
                            if (this.retryReasons.includes(retryReason)) {
                                await this.queueRequest(request, apiName, true);
                                return this.apis[apiName](request);
                            }
                            // if we arean't already paused, then we first pause any future requests
                            // while we attempt a retry, but before we retry the original request
                            // we need to await the beforeRetry(). any requests that are made in
                            // beforeRetry() need to include the __allowWhilePaused header so they
                            // can they be processed even while requests are technically paused
                            else {
                                this.pauseRequests(retryReason);
                                try {
                                    await beforeRetry(error, request, apiName);
                                    request.allowWhilePaused = true;
                                    return await this.apis[apiName](request).then((resp) => {
                                        this.resumeRequests(retryReason);
                                        return resp;
                                    });
                                } catch (e) {
                                    this.resumeRequests(retryReason, e as AxiosError);
                                }
                            }
                        }
                    }
                }

                if (error.response?.data) {
                    if (isValidationError(error)) {
                        return Promise.reject(new ValidationError(error.response?.data.errors));
                    }
                    if (isApiError(error)) {
                        return Promise.reject(new ApiError(error.response?.data));
                    }
                    return Promise.reject(error.response?.data);
                }
                return Promise.reject(error);
            }
        );

        return api;
    }

    registerRequestInterceptor(interceptor: RequestInterceptor['interceptor'], config?: Partial<Omit<RequestInterceptor, 'interceptor'>>): void {
        this.requestInterceptors.push({ interceptor, ...config });
    }

    registerRetryInterceptor(
        shouldRetry: RetryInterceptor['shouldRetry'],
        beforeRetry: RetryInterceptor['beforeRetry'],
        config?: Partial<Omit<RetryInterceptor, 'shouldRetry' | 'beforeRetry'>>
    ): void {
        this.retryInterceptors.push({ shouldRetry, beforeRetry, ...config });
    }

    protected transformResponse(data: unknown): unknown {
        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                data[i] = this.transformResponse(data[i]);
            }
        } else if (isPojo(data)) {
            for (const key of Object.keys(data)) {
                data[key] = this.transformResponse(data[key]);
            }
        } else {
            if (typeof data === 'string') {
                if (isoDateFormat.test(data)) {
                    return new Date(data);
                }
            }
        }
        return data;
    }

    protected queueRequest(request: Request, apiName: ApiName, highPriority?: boolean): Promise<Request> {
        return new Promise((resolve, reject) => {
            const requestToQueue = {
                apiName,
                resolve,
                reject,
                request,
            };

            if (highPriority) {
                this.requestQueue.unshift(requestToQueue);
            } else {
                this.requestQueue.push(requestToQueue);
            }
        });
    }

    protected pauseRequests(retryReason: string): void {
        this.retryReasons.push(retryReason);
    }

    protected resumeRequests(retryReason: string, cancelQueuedRequests?: boolean | AxiosError<unknown>): void {
        this.retryReasons = this.retryReasons.filter((reason) => reason !== retryReason);
        if (!this.retryReasons.length) {
            while (this.requestQueue.length) {
                const pendingRequest = this.requestQueue.shift();
                if (pendingRequest) {
                    const { resolve, reject, request } = pendingRequest;
                    if (cancelQueuedRequests) {
                        reject(cancelQueuedRequests === true ? 'Request was canceled.' : cancelQueuedRequests);
                    } else {
                        resolve(request);
                    }
                }
            }
        }
    }
}
