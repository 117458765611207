import { AppStore, ModalStore } from '..';
import { ApiService, RootServices } from '../../services';
import { AlertStore } from '../AlertStore/AlertStore';
import { AuthStore } from '../AuthStore';
import { LoaderStore } from '../LoaderStore/LoaderStore';
import { OnInitialized, RootStores } from '../RootStore';
import { StudyStore } from '../StudyStore';
import { ToastStore } from '../ToastStore/ToastStore';
import { ParticipantStore } from '../ParticipantStore';
import { StorageStore } from '../StorageStore/StorageStore';
import { RouterStore } from '../RouterStore/RouterStore';
import { AnalyticsStore } from '../AnalyticsStore/AnalyticsStore';
import { CommunicationPreferencesStore } from '../CommunicationPreferencesStore/CommunicationPreferencesStore';

export abstract class BaseStore implements OnInitialized, RootStores, RootServices {
    //services
    apiService!: ApiService;

    // stores
    loaderStore!: LoaderStore;
    toastStore!: ToastStore;
    alertStore!: AlertStore;
    modalStore!: ModalStore;
    routerStore!: RouterStore;
    appStore!: AppStore;
    authStore!: AuthStore;
    analyticsStore!: AnalyticsStore;
    storageStore!: StorageStore;
    studyStore!: StudyStore;
    participantStore!: ParticipantStore;
    communicationPreferencesStore!: CommunicationPreferencesStore;

    onInitialized(stores: RootStores, services: RootServices): void {
        Object.assign(this, services);
        Object.assign(this, stores);
    }
}
