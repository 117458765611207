import { useIonAlert, IonButton, IonList } from '@ionic/react';
import React, { useCallback, forwardRef, useImperativeHandle } from 'react';
import { FieldValues, useForm, FormProvider, Path, DefaultValues } from 'react-hook-form';
import { ValidationError } from '../../services/ApiService/ValidationError';
import { useStores } from '../../stores';
import { Fields, FieldsDef } from './Fields';

export interface FormProps<V extends FieldValues, R> {
    children?: React.ReactNode;
    defaultValues?: DefaultValues<V>;
    handleSubmit: (data: V) => Promise<R>;
    handleSuccess?: (response: R, data: V) => void;
    handleError?: (e: Error, data: V) => void;
    submitButton?: string;
    loadingMessage?: string;
    fields?: FieldsDef<V>;
    style?: React.CSSProperties;
}

function FormBase<V extends FieldValues, R>(
    { children, defaultValues, handleSubmit, handleSuccess, handleError, fields, submitButton, loadingMessage = 'Saving...', style }: FormProps<V, R>,
    ref: React.Ref<{ submit: () => void; reset: () => void }>
): React.ReactElement {
    const [alert] = useIonAlert();
    const form = useForm<V>({ mode: 'onSubmit', shouldUnregister: true, defaultValues });
    const { loaderStore } = useStores();

    const onSubmit = useCallback<(data: V) => void>(
        (data) => {
            loaderStore.loadBegin('form', { message: loadingMessage });
            handleSubmit(data)
                .then((response) => {
                    if (handleSuccess) {
                        handleSuccess(response, data);
                    }
                })
                .finally(() => {
                    loaderStore.loadEnd('form');
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((e: any) => {
                    if (e instanceof ValidationError) {
                        if (e.formErrors.length) {
                            e.formErrors.forEach(({ name, error }) => {
                                form.setError(name as Path<V>, error);
                            });
                        } else {
                            alert(e.errors[0].message);
                        }
                    } else if (handleError) {
                        handleError(e, data);
                    } else {
                        throw e;
                    }
                })
                .catch(() => {
                    alert({
                        header: 'Unknown Error',
                        message: 'There was a problem submitting the form. Please try again.',
                    });
                });
        },
        [alert, form, loadingMessage, loaderStore, handleSubmit, handleSuccess, handleError]
    );

    useImperativeHandle(
        ref,
        () => ({
            submit() {
                form.handleSubmit(onSubmit, (e) => console.log('form error', e))();
            },
            reset() {
                form.clearErrors();
                form.reset(undefined, { keepDefaultValues: true });
            },
        }),
        [form, onSubmit]
    );

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="uri-form" style={style}>
                <div>
                    {children}
                    {fields && (
                        <IonList>
                            <Fields fields={fields} />
                        </IonList>
                    )}
                </div>
                <input type="submit" style={{ position: 'absolute', visibility: 'hidden', left: -10000 }} />
                {submitButton && (
                    <IonButton type="submit" color="primary" expand="block">
                        {submitButton}
                    </IonButton>
                )}
            </form>
        </FormProvider>
    );
}

export const Form = forwardRef(FormBase) as <V extends FieldValues, R>(props: FormProps<V, R> & { ref?: React.Ref<HTMLFormElement> }) => React.ReactElement;
