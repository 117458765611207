import { makeObservable, observable, action, reaction } from 'mobx';
import { Plugins } from '@capacitor/core';
import { RootServices } from 'services';
import { BaseStore, RootStores } from 'stores';
const { Storage } = Plugins;

export class StorageStore extends BaseStore {
    @observable data = observable.map<string, unknown>();
    @observable ephemeralData = observable.map<string, unknown>();

    constructor() {
        super();
        makeObservable(this);
    }

    onInitialized(stores: RootStores, services: RootServices): void {
        super.onInitialized(stores, services);

        reaction(
            () => this.authStore.isAuthenticated,
            (authenticated) => {
                if (!authenticated) {
                    this.ephemeralData.clear();
                }
            }
        );

        reaction(
            () => this.data.toJSON(),
            (data) => {
                Storage.set({ key: 'persistent', value: JSON.stringify(data) });
            }
        );

        reaction(
            () => this.ephemeralData.toJSON(),
            (data) => {
                Storage.set({ key: 'ephemeral', value: JSON.stringify(data) });
            }
        );

        this.syncData();
    }

    @action.bound
    async syncData(): Promise<void> {
        const [persistentDataJson, ephermalDataJson] = await Promise.all([Storage.get({ key: 'persistent' }), Storage.get({ key: 'ephemeral' })]);
        this.data.replace(persistentDataJson.value ? JSON.parse(persistentDataJson.value) : []);
        this.ephemeralData.replace(ephermalDataJson.value ? JSON.parse(ephermalDataJson.value) : []);
    }
}
