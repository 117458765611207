import { IonItem, IonLabel } from '@ionic/react';
import React, { ComponentProps, useEffect, useRef, RefObject } from 'react';
import { FieldValues, FieldError as ReactFieldError } from 'react-hook-form';
import { Rifm } from 'rifm';
import { titleCase } from 'utils';
import { Field, FieldProps } from './Field';
import { FieldError } from './FieldError';

export interface InputProps<V extends FieldValues>
    extends Omit<FieldProps<V>, 'render'>,
        Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onBlur' | 'onChange' | 'value' | 'name' | 'defaultValue'> {
    label?: string;
    maskFormat?: (val: string) => string;
    labelPosition?: 'floating' | 'fixed' | 'stacked' | 'placeholder';
    autoFocus?: boolean;
    listItemLines?: ComponentProps<typeof IonItem>['lines'];
    listItemClassName?: string;
    inputRef?: RefObject<HTMLInputElement>;
}

export function Input<V extends FieldValues>(props: InputProps<V>): React.ReactElement {
    const {
        name,
        label,
        labelPosition = 'placeholder',
        listItemLines = 'full',
        listItemClassName,
        required,
        rules,
        maskFormat,
        className = '',
        defaultValue,
        placeholder,
        autoFocus,
        inputRef,
        children,
        ...inputProps
    } = props;
    const resolvedLabel = label || titleCase(name);

    let ref = useRef<HTMLInputElement>(null);
    if (inputRef) {
        ref = inputRef;
    }

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                if (ref.current) {
                    ref.current.focus();
                }
            }, 250);
        }
    }, [autoFocus]);

    const resolvedInputProps = {
        name,
        ref,
        className: `uri-input ${className}`,
        placeholder: placeholder !== undefined ? placeholder : labelPosition === 'placeholder' ? resolvedLabel : '',
        ...inputProps,
    };

    return (
        <Field<V>
            name={name}
            required={required}
            defaultValue={defaultValue}
            rules={rules}
            render={({ onChange, onBlur, value }, error: ReactFieldError | undefined) => (
                <>
                    <IonItem lines={listItemLines} className={listItemClassName}>
                        {labelPosition !== 'placeholder' && <IonLabel position={labelPosition}>{resolvedLabel}</IonLabel>}
                        {maskFormat ? (
                            <Rifm value={value} onChange={onChange} format={maskFormat}>
                                {({ value, onChange }) => <input onChange={onChange} onBlur={onBlur} value={String(value || '')} {...resolvedInputProps} />}
                            </Rifm>
                        ) : (
                            <input onChange={(e) => onChange(e.target.value)} onBlur={onBlur} value={String(value || '')} {...resolvedInputProps} />
                        )}
                        {children}
                    </IonItem>
                    <FieldError label={resolvedLabel} error={error} rules={rules} />
                </>
            )}
        />
    );
}
