import React, { RefObject } from 'react';
import { FieldValues } from 'react-hook-form';
import { Field, FieldProps } from './Field';

export interface InputHiddenProps<V extends FieldValues>
    extends Omit<FieldProps<V>, 'render'>,
        Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onBlur' | 'onChange' | 'value' | 'name' | 'defaultValue' | 'type' | 'placeholder'> {
    inputRef?: RefObject<HTMLInputElement>;
}

export function InputHidden<V extends FieldValues>(props: InputHiddenProps<V>): React.ReactElement {
    const { name, required, defaultValue, inputRef, ...inputProps } = props;

    return (
        <Field<V>
            name={name}
            required={required}
            defaultValue={defaultValue}
            render={({ onChange, onBlur, value }) => (
                <>
                    <input
                        type="hidden"
                        name={name}
                        ref={inputRef}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        value={String(value || '')}
                        {...inputProps}
                    />
                </>
            )}
        />
    );
}
