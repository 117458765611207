import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { action, computed, makeObservable, observable, reaction, when } from 'mobx';
import { RootServices, isApiError } from 'services';
import { RootStores } from 'stores';
import { BaseStore } from '../BaseStore/BaseStore';
import { AnalyticsCreateSession, AnalyticsEvent, AnalyticsSession } from './types';

export class AnalyticsStore extends BaseStore {
    // here null and undefined are used to distinguish between the
    // initial loading state (undefined) vs an unsuccessful token
    // refresh/create (null) or an intentional delay in order to give the
    // auth service a chance to authenticate.
    @observable sessionToken: string | undefined | null;

    @computed get initialized(): boolean {
        return this.sessionToken !== undefined;
    }

    constructor() {
        super();
        makeObservable(this);
    }

    onInitialized(stores: RootStores, services: RootServices): void {
        super.onInitialized(stores, services);

        // using this loader key prevents the app from rendering
        // until this service and any others that called loadBegin('preload')
        // call loadEnd('preload')
        this.loaderStore.loadBegin('preload');

        this.apiService.registerRequestInterceptor((request) => {
            if (this.sessionToken) {
                request.headers['x-session-token'] = this.sessionToken;
            }
        });

        this.apiService.registerRetryInterceptor(
            // 406 is a special error code that the analytics service
            // uses to indicate an invalid or expired analytics session token.
            // when that happens, we need to create a new session before any other
            // requests are made
            (error) => isApiError(error) && error.response?.data?.errorCode === 'analytics-session-invalid' && 'invalid-analytics-token',
            async () => {
                await this.refreshSession(true);
            }
        );

        // wait until the router store is finished initializing so we
        // can reliably parse the url and query string
        when(
            () => this.routerStore.initialized,
            async () => {
                // when the app is first loading, we need to handle the case where a session token
                // has been passed to the app (likely from a referral link)
                const sessionToken = this.routerStore.removeQueryParam('sessionToken');
                if (sessionToken) {
                    // after setting the token we immediately attempt a refresh so verify that
                    // the token is valid and belongs to this user. If its not valid, a new token
                    // will be returned
                    this.setSessionToken(sessionToken);
                    await this.refreshSession();
                } else {
                    const referrer = this.routerStore.removeQueryParam('referrer');
                    const channel = this.routerStore.removeQueryParam('channel');

                    // here we check for special query parameters that would indicate this
                    // user landed here from an active recruiting url so we forcibly create
                    // a new session, even if they had an existing one. Otherwise we attempt
                    // a refresh which will either find an existing session associated with the
                    // logged in user or create a new one
                    if (referrer || channel) {
                        console.log('creating session with referrer', { referrer, channel });
                        await this.createSession({ referrer, channel });
                    } else {
                        // if we don't have what we need to create a new session or refresh an existing one,
                        // we set the token to null. this will allow the auth service to attempt an automatic
                        // login so we have one more chance to associate an existing session with this instance
                        this.setSessionToken(null);

                        // since we've decided to pass the buck to the auth service, we need to wait until
                        // it is finished attempting an automatic login before we decide whether to refresh
                        // or create a new session. again, we are bordering on anti-patterns here in an effort
                        // to not co-mingle too many concerns across stores, however if this logic gets much more
                        // convoluted, we could benefit from gathering all this logic into a single place
                        when(
                            () => this.authStore.initialized,
                            () => {
                                if (this.authStore.isAuthenticated) {
                                    this.refreshSession();
                                } else {
                                    this.createSession();
                                }
                            }
                        );
                    }
                }
            }
        );

        // wait until we have a valid session token before we trigger any events and
        // indicate to the rest of the application that this store has finished "preloading"
        when(
            () => !!this.sessionToken,
            () => {
                this.triggerEvent({ type: 'participant-app.load', data: { page: this.routerStore.location?.pathname } });

                // in general, creating a reaction inside a reaction is NOT a good idea. In this case, we are sure
                // that this block of code will only ever be executed once per app load so its ok. By registering it
                // here we can be sure that the app load event fires first and we avoid extra page-view events
                reaction(
                    () => this.routerStore.location?.pathname,
                    (page, prevPage) => {
                        if (prevPage && page && prevPage !== '/logout') {
                            this.triggerEvent({ type: 'participant-app.page-view', data: { page } });
                        }
                    }
                );

                this.loaderStore.loadEnd('preload');
            }
        );
    }

    @action.bound
    async createSession(sessionData?: AnalyticsCreateSession, config?: AxiosRequestConfig): Promise<void> {
        try {
            const { data: session } = await this.apiService.apis.analytics.post<AnalyticsCreateSession, AxiosResponse<AnalyticsSession>>(
                'sessions',
                sessionData,
                config
            );
            this.setSessionToken(session.sessionToken);
        } catch {
            // we don't want an issue with the analytics service to prevent using
            // the app altogether so we just wipe out the session token silently
            // since the backend will happily process requests without a session token
            this.setSessionToken(null);
        }
    }

    @action.bound
    async refreshSession(clearExistingToken = false): Promise<void> {
        if (clearExistingToken) {
            this.setSessionToken(undefined);
        }
        try {
            // refresh session will always return a sessionToken, since it creates a session if a valid one doesn't exist.
            // if the existing session is associated with a user, but the user is no longer authenticated (i.e. they logged out)
            // then a new session will be created, even if the previous session wasn't expired. On the other hand, if the user
            // is already logged in but doesn't have the sessionToken (i.e. refresh), then this endpoint will attempt to return
            // the previous analytics session, assuming ip/useragent, etc match and the token hasn't expired. if this endpoint sees an
            // expired access token in the authorization header it will throw a 401 so the auth store's retry interceptor
            // will attempt to refresh the access token in an effort to preserve the existing (authorized) analytics session
            const { data: session } = await this.apiService.apis.analytics.post<AnalyticsSession>('sessions/refresh', undefined, {
                headers: { __allowWhilePaused: '1' },
            });
            this.setSessionToken(session.sessionToken);
        } catch {
            // we don't want an issue with the analytics service to prevent using
            // the app altogether so we just wipe out the session token silently
            // since the backend will happily process requests without a session token
            this.setSessionToken(null);
        }
    }

    @action.bound
    async triggerEvent(event: AnalyticsEvent): Promise<void> {
        try {
            // creating an event can extend the session expiration, so a session token (either existing or new one) is always returned
            const { data: session } = await this.apiService.apis.analytics.post<AnalyticsEvent, AxiosResponse<AnalyticsSession>>('events', event);
            this.setSessionToken(session.sessionToken);
        } catch {
            // we don't want an issue with the analytics service to prevent using
            // the app altogether so we just wipe out the session token silently
            // since the backend will happily process requests without a session token
            this.setSessionToken(null);
        }
    }

    @action.bound
    setSessionToken(sessionToken: string | undefined | null): void {
        this.sessionToken = sessionToken;
    }
}
