import { CheckboxGroupProps, Form, InputEmail, Input, CheckboxGroup } from '@/Form';
import { Divider } from '@/Generic';
import { SsoButton } from '@/Sso';
import { IonButton, IonList } from '@ionic/react';
import { useCallback } from 'react';
import { useStores } from 'stores';
import { usePromiseHandler } from 'utils';
import { Progress, SetProgress } from './Welcome';

type RegisterFormData = {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    agreeToTerms: number;
    agreeToMessaging: number;
    ageRequirementMet: number;
};

const agreeToTermsProps: CheckboxGroupProps<RegisterFormData> = {
    name: 'agreeToTerms',
    choiceLabelProps: {
        class: 'ion-text-wrap',
    },
    choices: [
        {
            value: 1,
            label: (
                <>
                    I have read and agree to the{' '}
                    <a href="https://www.uriux.com/privacy/" target="_blank" rel="noreferrer">
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a href="https://www.uriux.com/tos/" target="_blank" rel="noreferrer">
                        Terms of Service
                    </a>
                    . User Research International (URI) may store any data collected from the use of the website. URI may share this data with third parties as
                    necessary to provide a secure and reliable service.{' '}
                </>
            ),
        },
    ],
    rules: {
        required: {
            value: true,
            message: 'You must agree to the Privacy Policy and Terms of Service.',
        },
    },
};

const agreeToMessagingProps: CheckboxGroupProps<RegisterFormData> = {
    name: 'agreeToMessaging',
    choiceLabelProps: {
        class: 'ion-text-wrap',
    },
    choices: [
        {
            value: 1,
            label: <>I agree to receiving text messages from User Research International for registration and login. Message and data rates may apply.</>,
        },
    ],
    rules: {
        required: {
            value: true,
            message: 'You must agree to receiving text messages so you can register and login to our service.',
        },
    },
};

const ageRequirementMetProps: CheckboxGroupProps<RegisterFormData> = {
    name: 'ageRequirementMet',
    choiceLabelProps: {
        class: 'ion-text-wrap',
    },
    choices: [
        {
            value: 1,
            label: <>I am 18 or older.</>,
        },
    ],
    rules: {
        required: {
            value: true,
            message: 'You must be 18 years old or older to become a panelist.',
        },
    },
};

export const RegisterForm: React.FC<{ progress: Progress; setProgress: SetProgress }> = ({ progress, setProgress }) => {
    const { authStore } = useStores();

    const onSubmit = useCallback<(data: RegisterFormData) => Promise<void>>(
        async ({ firstName, lastName, email }) => {
            if (progress.phone && progress.phoneOtp) {
                return authStore.registerViaPhone({
                    firstName,
                    lastName,
                    email,
                    phone: progress.phone,
                    phoneOtp: progress.phoneOtp,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
            }
        },
        [authStore, progress]
    );

    const handleSubmit = usePromiseHandler(onSubmit, {
        errorCodes: {
            401: {
                type: 'alert',
                header: 'Phone verification code has expired. Please try again',
                handler: () => {
                    setProgress((p) => ({ ...p, step: 'register' }));
                },
            },
            409: {
                type: 'alert',
                header: 'Email Already in Use',
                message: 'Please try a different email',
            },
        },
    });

    return (
        <>
            <h1>Register</h1>
            <p>Please provide your name and email. Your email can be as a backup method to log in.</p>
            <SsoButton provider="facebook" action="register" phone={progress.phone} phoneOtp={progress.phoneOtp}>
                Register with Facebook
            </SsoButton>
            {/*
            <SsoButton provider="linkedin" action="register" phone={progress.phone} phoneOtp={progress.phoneOtp}>
                Register with LinkedIn
            </SsoButton>
            */}
            <Divider>or Provide Your Information Below</Divider>
            <Form handleSubmit={handleSubmit} submitButton="Get Started">
                <IonList>
                    <Input name="firstName" autoFocus />
                    <Input name="lastName" />
                    <InputEmail name="email" defaultValue={progress.email} disabled={!!progress.email} />
                    <Input name="phone" defaultValue={progress.phone} disabled={!!progress.phone} />
                    <CheckboxGroup {...agreeToTermsProps} />
                    <CheckboxGroup {...agreeToMessagingProps} />
                    <CheckboxGroup {...ageRequirementMetProps} />
                </IonList>
            </Form>
            <IonButton fill="clear" size="small" expand="full" onClick={() => setProgress({ step: 'register' })}>
                Back
            </IonButton>
        </>
    );
};
