import { UnauthenticatedPage } from '@/Layout';
import { CompletePasswordResetForm } from './CompletePasswordResetForm';
import { base64DecodeUrl, useQueryParams, useUnauthenticatedPage } from 'utils';

export const CompletePasswordReset: React.FC = () => {
    useUnauthenticatedPage();
    const qs = useQueryParams();
    const code = qs.get('code');

    const [email, resetCode] = base64DecodeUrl(code || '').split(':');

    return (
        <UnauthenticatedPage title="Password Reset" image="password-reset.png">
            <p>Fill out the form below to reset your password.</p>
            <CompletePasswordResetForm email={email} resetCode={resetCode} />
        </UnauthenticatedPage>
    );
};

export default CompletePasswordReset;
