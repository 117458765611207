import { IonInput, IonItem, IonLabel, useIonModal } from '@ionic/react';
import { FieldValues, UnpackNestedValue } from 'react-hook-form';
import { FormProps } from './Form';
import { InlineEditModal } from './InlineEditModal';
import { pencilOutline } from 'ionicons/icons';
import { noop } from 'utils';
import { FieldsDef } from './Fields';
import React, { useCallback } from 'react';

export interface InlineEditProps<V extends FieldValues, R> {
    label: string;
    value: string;
    handleSave?: (data: UnpackNestedValue<V>) => Promise<R>;
    fields?: FieldsDef<V>;
    saveButtonText?: string;
    displayValue?: string;
    displayInputType?: 'text' | 'password';
    children?: React.ReactNode;
    formProps?: Partial<FormProps<V, R>>;
    disabled?: boolean;
}

export function InlineEdit<V extends FieldValues, R>({
    label,
    value,
    handleSave,
    fields,
    saveButtonText,
    displayValue,
    formProps,
    displayInputType = 'text',
    disabled,
    children,
}: InlineEditProps<V, R>): React.ReactElement {
    const [present, dismiss] = useIonModal(InlineEditModal, {
        handleClose: () => dismiss(),
        title: `Edit ${label}`,
        saveButtonText: saveButtonText || `Save ${label}`,
        formProps: {
            handleSubmit: handleSave || noop,
            fields,
            ...formProps,
        },
        children,
    });

    const showModal = useCallback(() => {
        present({ keyboardClose: false, swipeToClose: true });
    }, [present]);

    const onKeyPress = useCallback<React.KeyboardEventHandler>(
        (e) => {
            if (e.key == 'Enter') {
                showModal();
            }
        },
        [showModal]
    );

    return (
        <IonItem
            className="uri-inline-edit"
            lines="full"
            detail={!disabled}
            onKeyPress={disabled ? undefined : onKeyPress}
            onClick={disabled ? undefined : showModal}
            detailIcon={pencilOutline}
        >
            <IonLabel position="stacked">{label}</IonLabel>
            <IonInput type={displayInputType} readonly value={displayValue || String(value)} />
        </IonItem>
    );
}
