import { InlineEdit } from '@/Form';
import { Observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { usePromiseHandler } from 'utils';

export const ChangeEmail: React.FC = () => {
    const { authStore } = useStores();

    const requestEmailChange = usePromiseHandler(
        authStore.requestEmailChange,
        {
            success: {
                type: 'toast',
                header: 'Change Request Received',
                message: 'To complete the change, please check your email for a verification link.',
            },
            error: {
                type: 'alert',
                header: 'Cannot Change Email',
                message: 'Please try again or contact support.',
            },
            errorCodes: {
                409: {
                    type: 'alert',
                    header: 'Email Already in Use',
                    message: 'Please try a different email',
                },
                PHONE_NOT_MOBILE: {
                    type: 'alert',
                    header: 'Email Change Not Allowed',
                    message: 'Your account must have a password before you can change your email.',
                },
            },
        },
        { rethrowErrors: true }
    );

    return (
        <Observer>
            {() => (
                <InlineEdit
                    label="Email"
                    value={authStore.user?.email || ''}
                    saveButtonText="Request Email Change"
                    handleSave={(data) => requestEmailChange(String(data.email))}
                    formProps={{
                        fields: {
                            email: {
                                fieldType: 'email',
                                autoFocus: true,
                                defaultValue: authStore.user?.email,
                            },
                        },
                    }}
                />
            )}
        </Observer>
    );
};
