export const lowerCaseFirst = (str: string): string => {
    return str.charAt(0).toLowerCase() + str.slice(1);
};

export const upperCaseFirst = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const camelCase = (str: string): string => {
    const [first, ...remaining] = str
        .trim()
        .replace(/([a-z])([^a-z]+)/g, '$1 $2')
        .split(/[_\s]+/);
    return lowerCaseFirst(first) + remaining.map(upperCaseFirst).join('');
};

export const titleCase = (str: string): string => {
    return str
        .trim()
        .replace(/([a-z])([^a-z]+)/g, '$1 $2')
        .split(/[_\s]+/)
        .map(upperCaseFirst)
        .join(' ')
        .trim();
};

export const base64Encode = (str: string): string => {
    return Buffer.from(str, 'utf8').toString('base64');
};

export const base64Decode = (str: string): string => {
    return Buffer.from(str, 'base64').toString('utf8');
};

export const base64EncodeUrl = (str: string): string => {
    return base64Encode(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/g, '');
};

export const base64DecodeUrl = (str: string): string => {
    let encoded = str.replace(/-/g, '+').replace(/_/g, '/');
    while (encoded.length % 4) {
        encoded += '=';
    }
    return base64Decode(encoded);
};

export const randomString = (length: number, chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'): string => {
    const bytes = crypto.getRandomValues(new Uint8Array(length));
    return bytes.reduce((acc, r) => `${acc}${chars.charAt(r % chars.length)}`, '');
};
