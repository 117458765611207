import { useMountEffect } from 'utils';

export interface ExternalRedirectProps {
    to: string;
}

export const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ to }) => {
    useMountEffect(() => {
        window.location.replace(to);
    });

    return null;
};
