import { IonButton } from '@ionic/react';
import { useState } from 'react';
import { useStores } from 'stores';
import { StudyApplication } from 'stores/ParticipantStore/types';
import { Study } from 'stores/StudyStore/types';
import { usePromiseHandler } from 'utils';
import './StudyDetails.css';

export type StudyActionButtonProps = {
    study: Study;
    redirectToLogin: () => void;
};

export const StudyActionButton: React.FC<StudyActionButtonProps> = ({ study, redirectToLogin }) => {
    const { authStore, participantStore, studyStore } = useStores();
    const { studyApplications } = participantStore;

    const [getStartedButtonDisabled, setGetStartedButtonDisabled] = useState(false);

    const studyApplication = studyApplications.value().find((application) => application.studyId === study.id);
    const { status, respondentStatus, screenerUrl, eligibility } = studyApplication || {};

    const resumeScreener = (screenerUrl: string) => {
        window.location.replace(screenerUrl);
    };

    const apply = usePromiseHandler(
        (studyId: number) => {
            setGetStartedButtonDisabled(true);
            return studyStore.apply(studyId);
        },
        {
            success: (application: StudyApplication) => ({
                type: 'custom',
                handler: () => {
                    window.location.replace(application.screenerUrl);
                },
            }),
            error: {
                type: 'alert',
                message: 'Whoops. Something went wrong. Please try again.',
                handler: () => {
                    setGetStartedButtonDisabled(false);
                },
            },
            errorCodes: {
                409: {
                    type: 'alert',
                    message: 'You have already applied for this study.',
                    handler: () => {
                        setGetStartedButtonDisabled(false);
                    },
                },
            },
        },
        { loadingMessage: 'Please hang tight while we prepare the application process...' }
    );

    if (study.status !== 'ACTIVE') {
        return (
            <div className="status-bar">
                <div>
                    <b>Closed</b>
                </div>
                <div>No longer accepting respondents.</div>
            </div>
        );
    } else if (!authStore.isAuthenticated) {
        return (
            <IonButton className="action-button" size="default" color="primary" expand="block" fill="solid" onClick={redirectToLogin}>
                Login to Get Started
            </IonButton>
        );
    } else if (eligibility === 'DISQUALIFIED' || status === 'REJECTED') {
        return (
            <div className="status-bar">
                <div>
                    <b>Completed</b>
                </div>
                <div>Did not match screener requirements.</div>
            </div>
        );
    } else if (eligibility === 'QUALIFIED' || eligibility === 'FLEX') {
        return (
            <div className="status-bar">
                <div>
                    <b>Completed</b>
                </div>
                <div>Thank you for completing this survey.</div>
            </div>
        );
    } else if (respondentStatus !== 'COMPLETED' && screenerUrl) {
        return (
            <IonButton
                className="action-button"
                size="default"
                color="tertiary"
                expand="block"
                fill="solid"
                onClick={() => resumeScreener(screenerUrl)}
                style={{ margin: 0 }}
            >
                Continue
            </IonButton>
        );
    } else {
        return (
            <IonButton
                className="action-button"
                size="default"
                color="primary"
                expand="block"
                fill="solid"
                disabled={getStartedButtonDisabled}
                onClick={() => apply(study.id)}
            >
                Get Started
            </IonButton>
        );
    }
};
