import { Form, InputPhone } from '@/Form';
import { useOtpModal } from '@/Misc';
import { IonList, IonButton } from '@ionic/react';
import { useCallback } from 'react';
import { useStores } from 'stores';
import { usePromiseHandler } from 'utils';
import { Progress, SetProgress } from './Welcome';

export const RegisterPhone: React.FC<{ progress: Progress; setProgress: SetProgress }> = ({ setProgress }) => {
    const { authStore, appStore, analyticsStore } = useStores();

    const checkPhoneAvailability = usePromiseHandler(authStore.checkPhoneAvailability, {
        success: (_, phone, phoneOtp) => ({
            type: 'custom',
            handler: () => {
                setProgress({ phone, phoneOtp, step: 'register-form' });
            },
        }),
        errorCodes: {
            409: (_, phone, phoneOtp) => ({
                type: 'toast',
                header: 'Invalid Number',
                message: 'You have been logged in since you already have an account.',
                handler: () => {
                    authStore.loginViaPhone(phone, phoneOtp);
                },
            }),
        },
    });

    const [showOtpModal] = useOtpModal({
        action: 'register',
        handleSubmit: ({ phone, otp }) => checkPhoneAvailability(phone, otp),
    });

    const handleOtpSend = usePromiseHandler(authStore.sendPhoneOtp, {
        success: (_, phone) => ({
            type: 'custom',
            handler: () => {
                showOtpModal(phone);
            },
        }),
        error: {
            type: 'alert',
            header: 'Failed Sending Code',
            message: 'We were unable to send the code to the number you provided. Please try a different number or contact support for help.',
        },
        errorCodes: {
            COUNTRY_NOT_SUPPORTED: (_, phone) => ({
                type: 'alert',
                header: 'Unsupported Country',
                message: 'Unfortunately the country you selected is not currently supported.',
                handler: () => {
                    const country = appStore.countries.value().find((country) => phone.startsWith(country.dialCode));
                    if (country) {
                        analyticsStore.triggerEvent({ type: 'participant-app.unsupported-country', data: { countryCode: country.isoCode } });
                    }
                },
            }),
            PHONE_NOT_MOBILE: {
                type: 'alert',
                header: 'Invalid Number',
                message: 'Phone number is not a mobile number.',
            },
            429: {
                type: 'alert',
                message: 'We are experiencing higher than usual activity. Please wait a few minutes and try again.',
            },
        },
    });

    const handleSubmit = useCallback(
        ({ phone }: { phone: string }) => {
            setProgress((p) => ({ ...p, phone }));
            return handleOtpSend(phone, 'register');
        },
        [setProgress, handleOtpSend]
    );

    return (
        <>
            <Form handleSubmit={handleSubmit} submitButton="Get started">
                <IonList>
                    <InputPhone name="phone" formattedInputName="phoneFormatted" />
                </IonList>
                <br />
            </Form>
            <IonButton fill="clear" size="small" expand="full" onClick={() => setProgress({ step: 'login' })}>
                Already have an account?
            </IonButton>
        </>
    );
};
