import { useEffect, useRef } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';
import { Input, InputProps } from './Input';

export type InputOtpProps<V extends FieldValues> = Omit<InputProps<V>, 'type'> & {
    codeLength: number;
    handleCode: (code: string) => void;
};

export function InputOtp<V extends FieldValues>(props: InputOtpProps<V>): React.ReactElement {
    const { rules = {}, codeLength, handleCode, ...inputProps } = props;
    rules.pattern = {
        value: new RegExp(`\\d{${codeLength}}$`),
        message: 'Please enter a valid code',
    };

    const code = useWatch({
        name: props.name,
    });

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current && code && code.length === codeLength) {
            inputRef.current.type = 'text';
            inputRef.current.setSelectionRange(0, 0);
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.blur();
                }
            }, 100);

            handleCode(code);
        }
    }, [codeLength, code, handleCode, inputRef]);

    return (
        <Input
            readOnly={code && code.length === codeLength}
            inputRef={inputRef}
            autoComplete="one-time-code"
            placeholder=""
            listItemClassName="uri-input-otp"
            listItemLines="none"
            type="number"
            inputMode="numeric"
            maxLength={codeLength}
            pattern="\d*"
            rules={rules}
            {...inputProps}
        >
            <div style={{ gridTemplateColumns: `repeat(${codeLength}, max-content)` }}>
                {[...Array(codeLength).keys()].map((i) => (
                    <div key={i}></div>
                ))}
            </div>
        </Input>
    );
}
