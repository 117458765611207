import { FieldError as ReactFieldError } from 'react-hook-form';
import { ControllerRules } from './Field';
import { alertCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

export const getErrorMessage = (error: ReactFieldError, label: string, rules: ControllerRules = {}): string => {
    const ruleValue = (rules as Record<string, string | number | boolean | { value: unknown; message: string }>)[error.type];
    if (typeof ruleValue === 'object' && ruleValue.message) {
        return ruleValue.message;
    }

    switch (error.type) {
        case 'required':
            return `${label} is required`;

        case 'min':
            return rules?.min ? `${label} must be at least ${rules.min}` : `Invalid ${label}`;

        case 'max':
            return rules?.max ? `${label} cannot be greater than ${rules.max}` : `Invalid ${label}`;

        case 'minLength':
            return rules?.minLength ? `${label} must be at least ${rules?.minLength} characters` : `Invalid ${label} length.`;

        case 'maxLength':
            return rules?.maxLength ? `${label} cannot be greater than ${rules?.minLength} characters` : `Invalid ${label} length.`;

        case 'manual':
        case 'validate':
            return error.message || `Invalid ${label}`;

        default:
            return `Invalid ${label}`;
    }
};

export interface FieldErrorProps {
    label: string;
    error?: ReactFieldError;
    rules?: ControllerRules;
}

export const FieldError: React.FC<FieldErrorProps> = ({ error, rules, label }) => {
    if (!error) return null;
    return (
        <div className="uri-form-error">
            <IonIcon icon={alertCircleOutline} />
            <div>{getErrorMessage(error, label, rules)}</div>
        </div>
    );
};
