/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */

import { ApiError } from 'services/ApiService/ApiError';
import { Alert } from 'stores/AlertStore/AlertStore';
import { Toast } from 'stores/ToastStore/ToastStore';
import { ValidationError } from '../services/ApiService/ValidationError';
import { BaseStore } from '../stores/BaseStore/BaseStore';

export interface ToastDecoratorOptions {
    success?: Toast | ((...args: unknown[]) => Toast);
    error?: true | Toast | ((e: Error) => Toast | boolean);
    catchError?: boolean;
}

export function toast({ success, error, catchError }: ToastDecoratorOptions) {
    return function (target: unknown, propertyKey: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor {
        const method = descriptor.value;
        descriptor.value = function (...args: unknown[]) {
            const self = this as BaseStore;
            return method
                .apply(self, args)
                .then((data: unknown) => {
                    if (success) {
                        const message = typeof success === 'function' ? success(data, ...args) : success;
                        self.toastStore.show('success', message);
                    }
                    return data;
                })
                .catch((e: Error) => {
                    const shouldHandleError = !(e instanceof ValidationError);
                    if (error && shouldHandleError) {
                        const toastOrBoolean = typeof error === 'function' ? error(e) : e.message || error;
                        if (typeof toastOrBoolean === 'boolean') {
                            if (toastOrBoolean) {
                                self.toastStore.show('error', e.message || 'Something unexpected happened. Please refresh and try again.');
                            }
                        } else {
                            self.toastStore.show('error', toastOrBoolean);
                        }
                    }
                    if (!catchError) {
                        throw e;
                    }
                });
        };
        return descriptor;
    };
}

export interface AlertDecoratorOptions {
    success?: Alert | ((...args: unknown[]) => Alert);
    error?: Alert | true | ((e: Error) => boolean | Alert);
    catchError?: boolean;
}

export function alert({ success, error, catchError }: AlertDecoratorOptions) {
    return function (target: unknown, propertyKey: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor {
        const method = descriptor.value;
        descriptor.value = function (...args: unknown[]) {
            const self = this as BaseStore;
            return method
                .apply(self, args)
                .then((data: unknown) => {
                    if (success) {
                        self.alertStore.show('info', typeof success === 'function' ? success(data, ...args) : success);
                    }
                    return data;
                })
                .catch((e: Error) => {
                    const shouldHandleError = !(e instanceof ValidationError);
                    if (error && shouldHandleError) {
                        const alertOrBoolean = typeof error === 'function' ? error(e) : error;
                        if (typeof alertOrBoolean === 'boolean') {
                            if (alertOrBoolean) {
                                self.alertStore.show('info', e.message || 'Something unexpected happened. Please refresh and try again.');
                            }
                        } else {
                            self.alertStore.show('info', alertOrBoolean);
                        }
                    }
                    if (!catchError) {
                        throw e;
                    }
                });
        };
        return descriptor;
    };
}

export const errorCodes =
    <T extends Alert | Toast>(codes: Partial<Record<number | 'default', T>>): ((e: Error) => T | boolean) =>
    (e: Error) => {
        if (e instanceof ApiError) {
            const alertOrToast: T | undefined = codes[e.statusCode] || codes.default;
            if (alertOrToast) {
                e.handled = true;
                return alertOrToast;
            }
            return false;
        }
        return true;
    };

type Method<A extends any[], R> = (...args: A) => R;
type MethodDecorator<T, A extends any[] = any[], R = any> = (
    target: T,
    key: string | symbol,
    descriptor: TypedPropertyDescriptor<Method<A, R>>
) => TypedPropertyDescriptor<Method<A, R>> | void;

export type Handler = {
    alert?: { header?: string; message?: string };
    toast?: { header?: string; message?: string };
    action?: () => any;
    redirect?: string;
};

const runHandler = (type: 'success' | 'error', handler: Handler, store: BaseStore) => {
    const { alertStore, toastStore, routerStore } = store;
    const { alert, toast, action, redirect } = handler;

    if (action) {
        action();
    }

    if (alert) {
        alertStore.show(type, alert);
    }

    if (toast) {
        toastStore.show(type, { ...toast, color: type === 'error' ? 'danger' : 'secondary' });
    }

    if (redirect) {
        if (redirect === 'back') {
            routerStore.back();
        } else {
            routerStore.push(redirect);
        }
    }
};

export type ErrorHandler = Handler & { rethrow?: boolean };

export type ErrorHandlerOptions = {
    [errorCode in number | string | 'default']: ErrorHandler | false;
};
export function error<A extends any[], R = any>(
    handlers: ErrorHandlerOptions | ((store: BaseStore, ...args: A) => ErrorHandlerOptions)
): MethodDecorator<BaseStore, A, Promise<R>> {
    return <T extends BaseStore>(target: T, methodName: string | symbol, descriptor: TypedPropertyDescriptor<Method<A, any>>) => {
        const method = descriptor.value;
        if (method) {
            descriptor.value = async function (this: T, ...args: A) {
                try {
                    await method.apply(this, args);
                } catch (e: any) {
                    const resolvedHandlers = typeof handlers === 'function' ? handlers(this, ...args) : handlers;
                    let handler: ErrorHandler | false | undefined;
                    if (e instanceof ApiError) {
                        const code = e.errorCode || e.statusCode.toString();
                        const handlerKey = Object.keys(resolvedHandlers).find((match) => {
                            return code.match(new RegExp(match));
                        });

                        if (handlerKey) {
                            handler = resolvedHandlers[handlerKey];
                        }
                    }

                    if (handler === undefined) {
                        handler = resolvedHandlers['default'] ?? {
                            type: 'toast',
                            title: 'Error',
                            message: e.message || 'An unknown error ocurred.',
                        };
                    }

                    if (handler === false) {
                        throw e;
                    }

                    runHandler('error', handler, this);

                    if (handler.rethrow) {
                        throw e;
                    }
                }
            };
        }

        return descriptor;
    };
}

export function success<A extends any[], R = any>(handler: Handler | ((store: BaseStore, ...args: A) => Handler)): MethodDecorator<BaseStore, A, Promise<R>> {
    return <T extends BaseStore>(target: T, methodName: string | symbol, descriptor: TypedPropertyDescriptor<Method<A, Promise<R>>>) => {
        const method = descriptor.value;
        if (method) {
            descriptor.value = async function (this: T, ...args: A) {
                const result: R = await method.apply(this, args);
                const resolvedHandler = typeof handler === 'function' ? handler(this, ...args) : handler;
                runHandler('success', resolvedHandler, this);
                return result;
            };
        }
        return descriptor;
    };
}
