import { Form, InputPhone } from '@/Form';
import { useOtpModal } from '@/Misc';
import { IonList, IonButton, useIonAlert } from '@ionic/react';
import { useCallback } from 'react';
import { useStores } from 'stores';
import { usePromiseHandler } from 'utils';
import { Progress, SetProgress } from './Welcome';

export const SetPhone: React.FC<{ progress: Progress; setProgress: SetProgress }> = ({ setProgress }) => {
    const { authStore } = useStores();

    const [showOtpModal] = useOtpModal({
        action: 'change_phone',
        loadingMessage: 'Saving phone...',
        handleSubmit: usePromiseHandler(({ phone, otp }) => authStore.setPhone(phone, otp), {
            success: {
                type: 'custom',
                redirect: '/',
            },
            errorCodes: {
                409: {
                    type: 'alert',
                    header: 'Phone In Use',
                    message: 'This phone number is associated with another account. Please try a new number or contact support for assistance.',
                },
            },
        }),
    });

    const handleOtpSend = usePromiseHandler(authStore.sendPhoneOtp, {
        success: (_, phone) => ({
            type: 'custom',
            handler: () => {
                showOtpModal(phone);
            },
        }),
        error: {
            type: 'alert',
            header: 'Failed Sending Code',
            message: 'We were unable to send the code to the number you provided. Please try a different number or contact support for help.',
        },
        errorCodes: {
            PHONE_NOT_MOBILE: {
                type: 'alert',
                header: 'Invalid Number',
                message: 'Phone number is not a mobile number.',
            },
            429: {
                type: 'alert',
                message: 'We are experiencing higher than usual activity. Please wait a few minutes and try again.',
            },
        },
    });

    const handleSubmit = useCallback(
        ({ phone }: { phone: string }) => {
            setProgress((p) => ({ ...p, phone }));
            return handleOtpSend(phone, 'change_phone');
        },
        [handleOtpSend, setProgress]
    );

    const [showInfoDialog] = useIonAlert();
    const onInfoClick = useCallback(() => {
        showInfoDialog({
            header: 'Phone Number',
            message:
                'Phone login provides a streamlined login experience that is more secure than traditional email and password. It also helps us protect against fraudulent activity so we can focus on you - our legitimate participant.<br /><br />URI will not sell your phone number.  No spam.  No robo calls.',
        });
    }, [showInfoDialog]);

    return (
        <>
            <p style={{ textAlign: 'center' }}>
                Moving forward, phone numbers are going to be required and will become the primary way to log in to the URI Participant Portal.
            </p>
            <Form handleSubmit={handleSubmit} submitButton="Get started" loadingMessage="Sending verification code...">
                <IonList>
                    <InputPhone name="phone" formattedInputName="phoneFormatted" />
                </IonList>
                <br />
            </Form>
            <IonButton fill="clear" size="small" expand="full" onClick={onInfoClick}>
                Why do we collect your phone number?
            </IonButton>
        </>
    );
};
