import { makeObservable, observable, action } from 'mobx';
import { Plugins } from '@capacitor/core';
import { BaseStore } from '../BaseStore/BaseStore';
import { lazyObservable } from 'utils';
import { Country } from './types';
const { Storage } = Plugins;

const MENU_ENABLED = 'menuEnabled';

export class AppStore extends BaseStore {
    @observable menuEnabled = true;

    readonly countries = lazyObservable<Country[]>(
        (sink) =>
            this.apiService.apis.project.get<Country[]>('countries').then(({ data }) => {
                sink(data);
            }),
        []
    );

    constructor() {
        super();
        makeObservable(this);
    }

    @action.bound
    async load(): Promise<void> {
        this.getMenuEnabled();
    }

    @action
    async getMenuEnabled(): Promise<void> {
        const response = await Storage.get({ key: MENU_ENABLED });
        this.menuEnabled = response.value === 'true';
    }

    @action.bound
    async setMenuEnabled(menuEnabled: boolean): Promise<void> {
        await Storage.set({ key: MENU_ENABLED, value: JSON.stringify(menuEnabled) });
        await this.getMenuEnabled();
    }

    // use this to prevent render components from being rendered multiple
    // times (e.g., a mobile version and a hidden desktop version).  even
    // when components are hidden via css media queries, their actions will
    // still get executed for each instance, hidden or not.  we can use
    // these variables to not render the component at all, but the tradeoff
    // is you lose responsiveness.
    readonly mobileView = window.matchMedia('(max-width: 768px)').matches;
    readonly desktopView = window.matchMedia('(min-width: 768px)').matches;
}
