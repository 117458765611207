import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { FieldValues, Path, useFormContext, useWatch } from 'react-hook-form';
import { useStores } from 'stores';
import { Input, InputProps } from './Input';
import { InputHidden } from './InputHidden';
import '../../theme/flags.css';
import { AuthenticatedPage } from '@/Layout';
import { IonItem, IonLabel, IonList, useIonModal } from '@ionic/react';
import { Observer } from 'mobx-react-lite';
import { Country } from 'stores/AppStore/types';
import { titleCase, useReaction } from 'utils';

export type CountrySelectModalProps = {
    handleClose: () => void;
    handleSelect: (country: Country) => void;
};

export const CountrySelectModal: React.FC<CountrySelectModalProps> = ({ handleClose, handleSelect }) => {
    const { appStore } = useStores();

    const onClick: MouseEventHandler<HTMLIonItemElement> = useCallback(
        (e) => {
            const countryCode = e.currentTarget.getAttribute('data-country-code');
            const country = appStore.countries.value().find((c) => c.isoCode == countryCode);
            if (country) {
                handleSelect(country);
                handleClose();
            }
        },
        [handleClose, handleSelect, appStore]
    );

    return (
        <AuthenticatedPage title={'Select Country'} secondaryAction="close" handleClose={handleClose}>
            <IonList className="country-select" lines="full">
                <Observer>
                    {() => (
                        <>
                            {appStore.countries.loading && <IonLabel>Loading</IonLabel>}
                            {appStore.countries.value().map((country) => (
                                <IonItem button key={country.isoCode} data-country-code={country.isoCode} onClick={onClick}>
                                    <IonLabel>
                                        <span className={`flag ${country.isoCode}`}></span>
                                        {country.name}
                                    </IonLabel>
                                    <IonLabel slot="end">{country.dialCode}</IonLabel>
                                </IonItem>
                            ))}
                        </>
                    )}
                </Observer>
            </IonList>
        </AuthenticatedPage>
    );
};

export type InputPhoneProps<V extends FieldValues, F> = Omit<InputProps<V>, 'type' | 'maskFormat'> & {
    formattedInputName: Path<F>;
};

const formatPhone = (str: string | undefined, example: string): string => {
    const digits = (str || '').replace(/[^\d]/g, '').split('');
    if (!digits.length) return '';
    const formatChars = example.replace(/\d/g, '#').split('');
    let formatted = '';
    for (const char of formatChars) {
        if (char === '#') {
            if (!digits.length) return formatted;
            formatted += digits.shift();
        } else {
            formatted += char;
        }
    }
    return formatted;
};

export function InputPhone<V extends FieldValues, F extends FieldValues>(props: InputPhoneProps<V, F>): React.ReactElement {
    const { appStore } = useStores();

    const { name, label, formattedInputName, rules = {}, ...inputProps } = props;

    const [country, setCountry] = useState<Country | undefined>(appStore.countries.value().find((country) => country.isoCode === 'US'));

    const [showCountrySelectModal, hideCountrySelectModal] = useIonModal(CountrySelectModal, {
        handleClose: () => hideCountrySelectModal(),
        handleSelect: setCountry,
    });

    const onCountrySelectClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            e.preventDefault();
            showCountrySelectModal();
        },
        [showCountrySelectModal]
    );

    const { setValue } = useFormContext();

    const formattedPhone = useWatch({
        name: formattedInputName,
    });

    useReaction(
        () => appStore.countries.value(),
        (countries) => {
            if (countries.length && !country) {
                setCountry(countries.find((country) => country.isoCode === 'US'));
            }
        }
    );

    useEffect(() => {
        if (formattedPhone && country) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(name, `${country.dialCode}${formattedPhone.replace(/[^\d]+/g, '')}` as any);
        }
    }, [formattedPhone, setValue, name, country]);

    return (
        <Input<F>
            name={formattedInputName}
            label={label || titleCase(name)}
            placeholder={country?.phoneExample || '(999) 999-9999'}
            className="uri-input-phone"
            type="tel"
            autoComplete="phone"
            rules={rules}
            maskFormat={country?.phoneExample ? (val) => formatPhone(val, country?.phoneExample) : undefined}
            {...inputProps}
        >
            {country && (
                <div className={'country'} onClick={onCountrySelectClick}>
                    <span className={`flag ${country.isoCode}`}></span>
                    {country.dialCode}
                </div>
            )}
            <InputHidden name={name} />
        </Input>
    );
}
