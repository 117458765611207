import { Form, InputPassword } from '@/Form';
import { IonButton, IonList } from '@ionic/react';
import { useCallback } from 'react';
import { useStores } from 'stores';
import { Progress, SetProgress } from './Welcome';

export const LoginPassword: React.FC<{ progress: Progress; setProgress: SetProgress }> = ({ progress, setProgress }) => {
    const { authStore } = useStores();

    const handleSubmit = useCallback(
        async (data: { password: string }) => {
            if (progress.email) {
                return authStore.loginViaEmail(progress.email, data.password);
            }
        },
        [authStore, progress]
    );

    return (
        <>
            <Form handleSubmit={handleSubmit} submitButton="Continue">
                <IonList>
                    <InputPassword name="password" />
                </IonList>
                <br />
            </Form>
            <IonButton fill="clear" size="small" expand="full" onClick={() => setProgress({ step: 'login' })}>
                Back
            </IonButton>
            <IonButton fill="clear" routerLink="/password-reset/request" size="small" expand="full" routerDirection="root">
                Forgot your password?
            </IonButton>
        </>
    );
};
