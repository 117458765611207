import { FieldValues } from 'react-hook-form';
import { Input, InputProps } from './Input';

export type InputEmailProps<V extends FieldValues> = Omit<InputProps<V>, 'type'>;

export function InputEmail<V extends FieldValues>(props: InputEmailProps<V>): React.ReactElement {
    const { rules = {}, ...inputProps } = props;
    rules.pattern = {
        value: /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        message: 'Please enter a valid email',
    };
    return <Input type="email" autoComplete="email" rules={rules} {...inputProps} />;
}
