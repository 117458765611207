import { CheckboxGroup, Form } from '@/Form';
import { Blurb } from '@/Generic';
import { AuthenticatedPage } from '@/Layout';
import { IonList } from '@ionic/react';
import { options } from 'ionicons/icons';
import { Observer } from 'mobx-react-lite';
import React, { createRef, useCallback } from 'react';
import { useStores } from 'stores';
import { usePromiseHandler } from 'utils';

export const StudyPreferences: React.FC = () => {
    const { studyStore, participantStore } = useStores();

    const formRef = createRef<HTMLFormElement>();

    const onSubmit = useCallback<(data: { profileIds: number[] }) => Promise<void>>(
        ({ profileIds }) => {
            return participantStore.updateParticipant({ profileIds });
        },
        [participantStore]
    );

    const handleSubmit = usePromiseHandler(onSubmit, {
        success: {
            type: 'toast',
            message: 'Your study preferences have been saved.',
            redirect: 'back',
        },
        error: {
            type: 'toast',
            message: 'Failed saving study preferences.',
        },
    });
    return (
        <AuthenticatedPage
            title="Customize your interests"
            backButtonHref={'/profile'}
            secondaryAction={'back'}
            primaryAction={'Save'}
            handlePrimaryAction={() => formRef.current?.submit()}
            loading={participantStore.participant.loading}
        >
            <Blurb title="Customize your interests" icon={options}>
                Select the types of studies that are most relevant to you.
            </Blurb>
            <Observer>
                {() => {
                    const { profileIds = [] } = participantStore.participant.value() || {};
                    const profileChoices = studyStore.profiles.value().map((profile) => ({
                        value: profile.id,
                        label: profile.name,
                        description: profile.description,
                    }));
                    return (
                        <Form ref={formRef} handleSubmit={handleSubmit} defaultValues={{ profileIds }}>
                            <IonList style={{ borderTop: '1px solid #ccc' }}>
                                <CheckboxGroup name="profileIds" choices={profileChoices} checkboxProps={{ slot: 'end' }} required={false} />
                            </IonList>
                        </Form>
                    );
                }}
            </Observer>
        </AuthenticatedPage>
    );
};
