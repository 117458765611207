import React, { useCallback } from 'react';
import { useStores } from 'stores';
import { Form, InputEmail } from '@/Form';
import { usePromiseHandler } from 'utils';

type RequestPasswordResetFormData = {
    email: string;
};

export const RequestPasswordResetForm: React.FC<{ email?: string }> = ({ email }) => {
    const { authStore } = useStores();

    const onSubmit = useCallback<(data: RequestPasswordResetFormData) => Promise<void>>(
        ({ email }) => {
            return authStore.requestPasswordReset(email);
        },
        [authStore]
    );

    const handleSubmit = usePromiseHandler(onSubmit, {
        success: {
            type: 'toast',
            message: 'A password reset request has been sent. Please check your inbox.',
            redirect: '/welcome?step=login',
        },
        errorCodes: {
            403: (_, { email }) => ({
                type: 'alert',
                header: 'Email Not Verified',
                message: 'This email requires verification before you can request a password reset.',
                redirect: `/email-verification/pending?email=${encodeURIComponent(email)}`,
            }),
            404: {
                type: 'alert',
                header: 'Account Not Found',
                message: 'We could not find an account associated with the email provided. Please try again.',
            },
            410: {
                type: 'alert',
                header: 'Account Deactivated',
                message: 'Please contact support.',
            },
        },
    });

    return (
        <Form handleSubmit={handleSubmit} submitButton="Request Password Reset">
            <InputEmail name="email" defaultValue={email} required autoFocus />
        </Form>
    );
};
