import { useContext } from 'react';
import { useMountEffect, usePromiseHandler, useQueryParams } from 'utils';
import { useStores } from 'stores';
import { IonRouterContext } from '@ionic/react';

export const Reception: React.FC = () => {
    const { authStore, routerStore } = useStores();
    const router = useContext(IonRouterContext);
    const qs = useQueryParams();

    const email = qs.get('email');
    const redirect = qs.get('redirect');

    const checkEmailExists = usePromiseHandler(authStore.emailExists, {
        success: (exists, email) => ({
            type: 'toast',
            message: `Please ${exists ? 'login' : 'register'} to continue.`,
            redirect: exists ? `/welcome?step=login&email=${encodeURIComponent(email)}` : `/welcome?step=register&email=${encodeURIComponent(email)}`,
        }),
    });

    useMountEffect(() => {
        if (authStore.isAuthenticated) {
            router.push(redirect || '/', 'root');
        } else {
            if (redirect) {
                routerStore.setDefaultLocation(redirect);
            }

            if (email) {
                checkEmailExists(email);
            } else {
                router.push(`/welcome?step=login`, 'root');
            }
        }
    });

    return null;
};
