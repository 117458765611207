import { ErrorPage } from '@/Layout/ErrorPage';
import { IonButton } from '@ionic/react';

export const ErrorAccountLocked: React.FC = () => (
    <ErrorPage message="Oops!  Your account is locked.">
        <p>To request to have your account unlocked, please contact our support team.</p>
        <br />
        <IonButton color="primary" expand="block" href="mailto:portalsupport@uriux.com?subject=Request Account Unlock">
            Contact Support
        </IonButton>
    </ErrorPage>
);
