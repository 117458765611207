import { randomString, base64EncodeUrl } from './string';

const bufferToBase64 = (buffer: ArrayBuffer): string => window.btoa(String.fromCharCode(...Array.from(new Uint8Array(buffer))));
const crypto = window.crypto;
const encoder = new TextEncoder();

export const generatePkce = async (): Promise<{ verifier: string; challenge: string }> => {
    const verifier = randomString(64);

    const challengeDigest = await crypto.subtle.digest('SHA-256', encoder.encode(verifier));
    const challenge = base64EncodeUrl(bufferToBase64(challengeDigest));

    return { verifier, challenge };
};
