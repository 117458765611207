import { useRef, useCallback } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { Input, InputProps } from './Input';

export type InputPasswordProps<V extends FieldValues> = Omit<InputProps<V>, 'type'> & {
    match?: Path<V>;
};

export function InputPassword<V extends FieldValues>(props: InputPasswordProps<V>): React.ReactElement {
    const { match, autoComplete = 'password', rules = {}, ...inputProps } = props;

    const { watch } = useFormContext<V>();

    if (rules.minLength === undefined && !match) {
        rules.minLength = 10;
    }

    const password = useRef('');
    const validate = useCallback<(v: PathValue<V, Path<V>>) => boolean | string>((value) => {
        return value === password.current || 'The passwords do not match';
    }, []);

    if (match) {
        password.current = watch(match) as string;
        rules.validate = validate;
    }

    return <Input type="password" autoComplete={autoComplete} rules={rules} {...inputProps} />;
}
