import { useIonRouter } from '@ionic/react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useStores } from 'stores';

export const AuthenticatedRoute: React.FC<RouteProps> = (props) => {
    const { authStore, routerStore } = useStores();
    const { routeInfo } = useIonRouter();
    const { pushedByRoute, pathname, lastPathname = pathname } = routeInfo;

    // even if the authStore says the user is authenticated, we check to see
    // if the user has verified their email address and phone number, and if
    // not force them to do so. Note that this is purely enforced by the client:
    // the backed considers the user authenticated regardless.
    if (!authStore.isAuthenticated || !authStore.user?.emailIsVerified || !authStore.user?.phone) {
        // This is a bit hacky but the best we could
        // do considering the wackiness that is ionic routing.
        // By checking "pushedByRoute" we can usually distinguish between route
        // changes that were triggered within the app, vs an initial
        // route change during first page load. We also check against a list
        // of routes we absolutely do not want to ever be redirected to post login,
        // especially those routes that trigger a programmatic logout (i.e. password change).
        // Finally, a safety pig is included for extra protection should things
        // still not function correctly.
        //                          _
        //  _._ _..._ .-',     _.._(`))
        //  '-. `     '  /-._.-'    ',/
        //     )         \            '.
        //    / _    _    |             \
        //   |  a    a    /              |
        //   \   .-.                     ;
        //    '-('' ).-'       ,'       ;
        //       '-;           |      .'
        //          \           \    /
        //          | 7  .__  _.-\   \
        //          | |  |  ``/  /`  /
        //         /,_|  |   /,_/   /
        //            /,_/      '`-'
        //
        if (!pushedByRoute && lastPathname && !['/logout', '/reception', '/profile/personal', '/'].includes(lastPathname)) {
            routerStore.setDefaultLocation(lastPathname);
        }

        if (authStore.isAuthenticated) {
            return <Redirect to={authStore.user?.phone ? '/email-verification/pending' : '/welcome?step=set-phone'} />;
        }

        // if we are here, then the user hasn't logged in yet at all
        return <Redirect to="/welcome?step=login" />;
    }

    return <Route {...props} />;
};

export default AuthenticatedRoute;
