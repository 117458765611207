import { BaseStore } from '../BaseStore/BaseStore';
import { EmailTemplate, UserPreferences } from './types';
import { lazyObservable } from 'utils';
import { action, computed, makeObservable } from 'mobx';
import { arrayGroupBy } from 'utils/array';

export class CommunicationPreferencesStore extends BaseStore {
    readonly emailTemplates = lazyObservable<EmailTemplate[]>(async (sink) => {
        const { data: emailTemplates } = await this.apiService.apis.communication.get<EmailTemplate[]>('email-templates');
        sink(emailTemplates.sort((a, b) => a.name.localeCompare(b.name)));
    }, []);

    readonly userPreferences = lazyObservable<UserPreferences | undefined>(async (sink) => {
        const { data: userPreferences } = await this.apiService.apis.communication.get<UserPreferences>('user-preferences/me');
        sink(userPreferences);
    });

    @computed
    get emailTemplatesByGroup(): Record<string, EmailTemplate[]> {
        return arrayGroupBy(this.emailTemplates.value(), 'group');
    }

    constructor() {
        super();
        makeObservable(this);
    }

    @action.bound
    async updatePreferences(data: Partial<UserPreferences>): Promise<void> {
        const { data: userPreferences } = await this.apiService.apis.communication.patch<UserPreferences>('user-preferences/me', data);
        this.userPreferences.set(userPreferences);
    }
}
