import { makeObservable, observable, action } from 'mobx';
import { BaseStore } from '../BaseStore/BaseStore';
import { delay } from '../../utils';
import { Modal } from './Modal';
import type { ModalOptions, Obj } from './types';

export class ModalStore extends BaseStore {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @observable modals: Modal<any>[] = [];

    constructor() {
        super();
        makeObservable(this);
    }

    @action.bound
    open<P extends Obj = Obj>(modalOptions: Omit<ModalOptions<P>, 'componentProps'>, componentProps: P): Modal<P> {
        const modal = new Modal<P>(this, { ...modalOptions, componentProps });
        this.modals = [modal, ...this.modals];
        return modal;
    }

    @action.bound
    update(modalId: string, props: Partial<Omit<Modal, 'id' | 'type'>>): void {
        const modal = this.modals.find((m) => modalId === m.id);
        if (!modal) return;
        Object.assign(modal, props);
    }

    @action.bound
    async close(modalId: string): Promise<void> {
        this.hide(modalId);
        await delay(500);
        this.modals = this.modals.filter((m) => m.id !== modalId);
    }

    @action.bound
    show(modalId: string): void {
        const modal = this.modals.find((m) => modalId === m.id);
        if (!modal) return;
        this.update(modalId, { hidden: false });
    }

    @action.bound
    hide(modalId: string): void {
        this.update(modalId, { hidden: true });
    }

    @action.bound
    closeAll(): void {
        this.modals = [];
    }
}
