export const transformValues = <V, T>(obj: Record<string, V>, transformer: (value: V, key: string) => T): Record<string, T> => {
    return Object.entries(obj).reduce((o, [key, value]) => {
        o[key] = transformer(value, key);
        return o;
    }, {} as Record<string, T>);
};

export const isPojo = (obj: unknown): obj is Record<string, unknown> => {
    if (typeof obj !== 'object' || obj === null) return false;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const proto = Object.getPrototypeOf(obj);
    return proto !== null && Object.getPrototypeOf(proto) === null;
};
