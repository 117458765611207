import { IonIcon, IonInput, useIonModal, useIonToast } from '@ionic/react';
import { StudyInfo } from 'stores/StudyStore/types';
import { getStudyDisplayGratuity, getStudyDisplayType, getStudyImage, getStudyParticipantStatus } from './helpers';

import './StudyListItem.css';
import { copyOutline, shareOutline } from 'ionicons/icons';
import { AuthenticatedPage } from '@/Layout';
import { useStores } from 'stores';
import { useCallback } from 'react';
import { StudyActionButton } from './details/StudyActionButton';

export interface StudyListItemProps {
    study: StudyInfo;
    showImage?: boolean;
}

const ShareStudyModal: React.FC<{ studyId: number; handleClose: () => void }> = ({ studyId, handleClose }) => {
    const { participantStore, authStore } = useStores();
    let url = `https://app.uriux.com/studies/${studyId}?referrer=portal-share`;
    if (authStore.isAuthenticated) {
        url = `${url}&channel=${participantStore.participant.value()?.email}`;
    }
    const [present] = useIonToast();
    const presentToast = () => {
        present({
            message: 'The link was copied to your clipboard.',
            duration: 1500,
            position: 'middle',
        });
    };
    const handleCopyShare = () => {
        navigator.clipboard.writeText(url);
        presentToast();
    };

    return (
        <AuthenticatedPage title="Share Study" secondaryAction="close" handleClose={() => handleClose()}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '40px', margin: '40px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                        <img src="assets/img/referral-program.png" alt="Share Study" style={{ width: '50%' }} />
                        <div style={{ textAlign: 'center' }}>
                            <b>Know someone who might be interested?</b>
                        </div>
                        <div style={{ textAlign: 'center' }}>{`Share this link with a friend.`}</div>
                    </div>
                </div>{' '}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: '0 0 100%', gap: '10px' }}>
                    <div style={{ display: 'flex', flexGrow: '1', border: '0.5px solid #e8e8e8', borderRadius: '5px', padding: '3px' }}>
                        <IonInput type="text" value={url} readonly color="medium"></IonInput>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', marginRight: '5px', cursor: 'pointer' }} onClick={() => handleCopyShare()}>
                        <IonIcon icon={copyOutline} style={{ fontSize: '20px' }}></IonIcon>
                    </div>
                </div>
            </div>
        </AuthenticatedPage>
    );
};

export const StudyListItem: React.FC<StudyListItemProps> = ({ study, showImage = false }) => {
    const { profileId, location, description, headline } = study;
    const { routerStore } = useStores();

    const redirectToLogin = useCallback(() => {
        if (study) {
            routerStore.setDefaultLocation(`/studies/${study.id}`);
        }
        routerStore.push('/welcome?step=login', 'root');
    }, [study, routerStore]);

    const [showShareStudyModal, hideShareStudyModal] = useIonModal(ShareStudyModal, {
        studyId: study.id,
        handleClose: () => {
            hideShareStudyModal();
        },
    });

    const shouldShowGratuity = useCallback((study) => study.gratuityMin > 0 && study.gratuityMax > 0, []);

    return (
        <div className={'wrapper'}>
            {showImage && (
                <div className="image-wrapper">
                    <div className={`image image-${getStudyImage(profileId)}-${(Number(study.id.toString().slice(-1)) % 3) + 1}`}></div>
                </div>
            )}
            <div className="header">
                <div className="profile-title">
                    <div className="title">{headline}</div>
                    <div className="share" style={{ cursor: 'pointer' }} onClick={() => showShareStudyModal()}>
                        <IonIcon icon={shareOutline} color="primary"></IonIcon>
                    </div>
                </div>
                <div className="description">{description}</div>
                <div className="description">{study.requirements}</div>
                {!!study.additionalDetails && <div className="description">{study.additionalDetails}</div>}
                {shouldShowGratuity(study) ? (
                    <div className="type-gratuity-wrapper">
                        {getStudyDisplayType(study.type) !== 'Miscellaneous' && (
                            <div className="type" style={{ display: 'flex', flex: 1, flexGrow: 1 }}>
                                {getStudyDisplayType(study.type)}
                            </div>
                        )}
                        <div className="gratuity">{getStudyDisplayGratuity(study)}</div>
                    </div>
                ) : (
                    <>{getStudyDisplayType(study.type) !== 'Miscellaneous' && <span className="type">{getStudyDisplayType(study.type)}</span>}</>
                )}
                <div className="description">{location}</div>
            </div>
            {study.studyParticipants && study.studyParticipants.length > 0 ? (
                study.studyParticipants.map((sp) => (
                    <div key={sp.id} className="study-participation">
                        <div className="participation-details">
                            <div className="participation-detail">
                                <div>
                                    <b>{sp.group.name}</b>
                                </div>
                            </div>
                            <div className="participation-detail">
                                <div>{getStudyParticipantStatus(sp.status)}</div>
                                <div>/ ${sp.gratuity}</div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <StudyActionButton study={study} redirectToLogin={redirectToLogin} />
            )}
        </div>
    );
};
