import { observable, makeObservable, action, computed, when } from 'mobx';
import { BaseStore } from '../BaseStore/BaseStore';
import { RootStores } from '../RootStore';
import { RootServices } from '../../services/RootService';
import { generatePkce } from '../../utils/auth';
import { config } from '../../config';
import { AuthData, AuthRegisterData, AuthUser, PermissionConditions } from './types';
import { base64DecodeUrl, error, success } from 'utils';

export class AuthStore extends BaseStore {
    // here null and undefined are used to distinguish between the
    // initial loading state (undefined) vs an unsuccessful auto
    // login/refresh (null). this is used by other stores as a way
    // to wait until we know whether a person is logged in already
    // after an initial refresh. the initialized() getter abstracts
    // this detail away nicely but a note was still warranted
    @observable user: AuthUser | undefined | null;
    @observable authData: AuthData | undefined;

    @computed get initialized(): boolean {
        return this.user !== undefined;
    }

    @computed get isAuthenticated(): boolean {
        return !!this.user;
    }

    constructor() {
        super();
        makeObservable(this);
    }

    onInitialized(stores: RootStores, services: RootServices): void {
        super.onInitialized(stores, services);

        // using this loader key prevents the app from rendering
        // until this service and any others that called loadBegin('preload')
        // call loadEnd('preload')
        this.loaderStore.loadBegin('preload');

        this.routerStore.addRedirect(({ pathname }) => {
            if (pathname === '/logout') {
                this.logout();
                return 'login';
            } else if (this.user && !this.user.phone) {
                return { pathname: '/welcome', search: '?step=set-phone' };
            }
        });

        this.apiService.registerRequestInterceptor((request) => {
            if (this.authData?.accessToken) {
                request.headers.Authorization = `Bearer ${this.authData?.accessToken}`;
            }
        });

        this.apiService.registerRetryInterceptor(
            (error) => this.authData && error.response?.status === 401 && 'not-authenticated',
            async (error) => {
                // although hacky, we use a special header to indicate to the API service that
                // this request should be allowed, even though requests inside retry interceptors
                // are generally paused
                const wasRefreshed = await this.refreshSession();
                if (!wasRefreshed) {
                    throw error;
                }
            }
        );

        // we hold off on processing auth related urls and/or attempting
        // an auth session refresh until the analytics store has had a chance to
        // create a sessionToken that we can pass along with auth related requests
        // or decides to wait until the auth store has finished attempting
        // a refresh before ultimately creating a session
        when(
            () => this.analyticsStore.initialized,
            async () => {
                const initialPath = this.routerStore.location?.pathname;
                // todo: make this redirect uri generic (i.e. /oauth)
                // we have to handle this callback _before_ attempting a
                // session refresh, since we need to parse the analytics
                // session token first, before refreshing the auth session
                if (initialPath?.startsWith('/sso/')) {
                    await this.handleOauthCallback();
                } else {
                    await this.refreshSession();

                    if (initialPath === '/email-verification/complete') {
                        await this.completeEmailVerification();
                    } else if (initialPath === '/email-change/complete') {
                        await this.completeEmailChange();
                    }
                }
                this.loaderStore.loadEnd('preload');
            }
        );
    }

    @action.bound
    @error((_, email: string, _password: string) => ({
        SCAMMER: {
            redirect: `/account-locked`,
        },
        PHONE_NOT_MOBILE: {
            alert: {
                message: 'Your phone number must be a mobile number.',
            },
        },
        401: {
            alert: {
                header: 'Login Failed',
                message: 'Invalid email or password. Please try again.',
            },
        },
        403: {
            alert: {
                header: 'Email not verified',
                message: 'Please verify your email before logging in.',
            },
            redirect: `/email-verification/pending?email=${encodeURIComponent(email)}`,
        },
    }))
    async loginViaEmail(email: string, password: string): Promise<void> {
        const { data: authData } = await this.apiService.apis.auth.post<AuthData>('auth/email/login', {
            email,
            password,
        });
        await this.completeAuth(authData, 'login');
    }

    // TODO: remove ability to pass email to this endpoint once
    // we've fully transitioned to phone-based login
    @action.bound
    @error({
        SCAMMER: {
            redirect: `/account-locked`,
        },
    })
    async loginViaPhone(phoneOrEmail: string, phoneOtp: string): Promise<void> {
        if (phoneOrEmail.includes('@')) {
            const { data: authData } = await this.apiService.apis.auth.post<AuthData>('auth/phone/login', {
                email: phoneOrEmail,
                phoneOtp,
            });
            await this.completeAuth(authData, 'login');
        } else {
            const { data: authData } = await this.apiService.apis.auth.post<AuthData>('auth/phone/login', {
                phone: phoneOrEmail,
                phoneOtp,
            });
            await this.completeAuth(authData, 'login');
        }
    }

    @action.bound
    async oauthViaFacebook(action: 'login' | 'register', timeZone?: string, phone?: string, phoneOtp?: string): Promise<void> {
        const { verifier } = await generatePkce();
        window.localStorage.setItem('auth-verifier', verifier);
        const params = new URLSearchParams({
            auth_type: 'rerequest',
            response_type: 'code granted_scopes',
            client_id: config.facebook.clientId,
            scope: 'public_profile email',
            redirect_uri: `${config.baseUrl}/sso/facebook`,
            state: JSON.stringify({
                sessionToken: this.analyticsStore.sessionToken,
                provider: 'facebook',
                action,
                verifier,
                phone,
                phoneOtp,
                timeZone,
            }),
        }).toString();

        // in general using the global window object like this is discouraged, but since we need to redirect
        // to an external url we have no choice. don't make a habit of it
        window.location.replace(`https://www.facebook.com/v18.0/dialog/oauth?${params}`);
    }

    @action.bound
    async oauthViaLinkedin(action: 'login' | 'register', timeZone?: string, phone?: string, phoneOtp?: string): Promise<void> {
        const { verifier } = await generatePkce();
        window.localStorage.setItem('auth-verifier', verifier);
        const params = new URLSearchParams({
            response_type: 'code',
            client_id: config.linkedin.clientId,
            scope: 'openid profile email',
            redirect_uri: `${config.baseUrl}/sso/linkedin`,
            state: JSON.stringify({
                sessionToken: this.analyticsStore.sessionToken,
                provider: 'linkedin',
                action,
                verifier,
                phone,
                phoneOtp,
                timeZone,
            }),
        }).toString();

        // in general using the global window object like this is discouraged, but since we need to redirect
        // to an external url we have no choice. don't make a habit of it
        window.location.replace(`https://www.linkedin.com/oauth/v2/authorization?${params}`);
    }

    @action.bound
    async registerViaPhone(data: AuthRegisterData): Promise<void> {
        const { data: authData } = await this.apiService.apis.auth.post<AuthData>('auth/phone/register', data);
        await this.completeAuth(authData, 'register');
    }

    @action.bound
    async logout(): Promise<void> {
        this.authData = undefined;
        window.localStorage.removeItem('auth-redirect');
        const user = this.user;
        if (user) {
            await this.apiService.apis.auth.post('session/logout');
        }
        this.user = null;
        //this.routerStore.replace('/login');
    }

    @action.bound
    async emailExists(email: string): Promise<boolean> {
        const { data } = await this.apiService.apis.auth.post<{ exists: boolean }>('auth/email/exists', { email });
        return data.exists;
    }

    @action.bound
    async checkPhoneAvailability(phone: string, phoneOtp: string): Promise<void> {
        await this.apiService.apis.auth.post<{ exists: boolean }>('auth/phone/available', { phone, phoneOtp });
    }

    @action.bound
    async requestEmailVerification(email: string): Promise<void> {
        await this.apiService.apis.auth.post('auth/email/request-email-verification', { email });
    }

    @action.bound
    @error({
        default: {
            alert: {
                header: 'Verification Invalid',
                message: 'The verification code is invalid. Please try again or contact support.',
            },
            redirect: '/email-verification/error',
        },
        403: {
            alert: {
                header: 'Verification Link Expired',
                message: 'The verification code has expired. Please check your email for a new verification link.',
            },
            redirect: `/email-verification/pending`,
        },
        409: {
            toast: {
                header: 'Verification Complete',
                message: 'Your email address has already been verified.',
            },
        },
    })
    @success((store) => ({
        toast: {
            header: 'Verification Complete',
            message: store.authStore.isAuthenticated ? 'Your email address has been verified.' : 'Your email address has been verified. You may now log in.',
        },
        redirect: store.authStore.isAuthenticated ? '/tour' : '/welcome?step=login',
    }))
    async completeEmailVerification(): Promise<void> {
        const { code } = this.routerStore.query;
        if (!code) {
            throw new Error('Missing verification code.');
        }

        const [email, verificationCode] = base64DecodeUrl(code).split(':');

        await this.apiService.apis.auth.post('auth/email/complete-email-verification', {
            email,
            verificationCode,
        });
    }

    @action.bound
    async requestPasswordReset(email: string): Promise<void> {
        await this.apiService.apis.auth.post('auth/email/request-password-reset', { email });
    }

    @action.bound
    async completePasswordReset(email: string, password: string, resetCode: string): Promise<void> {
        await this.apiService.apis.auth.post('auth/email/complete-password-reset', { email, password, resetCode });
    }

    @action.bound
    async requestEmailChange(email: string): Promise<void> {
        await this.apiService.apis.auth.post('users/me/request-email-change', {
            email,
        });
    }

    @action.bound
    @error({
        default: {
            alert: {
                header: 'Verification Invalid',
                message: 'The verification code is invalid. Please try again or contact support.',
            },
        },
    })
    @success({
        toast: {
            header: 'Change Complete',
            message: 'Your email address has been changed successfully. You may now login.',
        },
        redirect: '/logout',
    })
    async completeEmailChange(): Promise<void> {
        const { code } = this.routerStore.query;
        if (!code) {
            throw new Error('Missing verification code.');
        }

        await this.apiService.apis.auth.post('users/me/change-email', { code });
    }

    @action.bound
    // TODO: when we can remove the below method, change the backend
    // to destroy all _other_ auth sessions when phone changes
    async changePhone(phone: string, phoneOtp: string): Promise<void> {
        await this.apiService.apis.auth.post('users/me/change-phone', {
            phone,
            phoneOtp,
        });

        const { data } = await this.apiService.apis.auth.get<AuthUser>('users/me');
        this.user = data;
    }

    // TODO: this method is temporary while we allow legacy users to
    // log in with email/password in order to capture a verified phone
    // number. When all existing users have a verified phone we can
    // safely remove this method.
    @action.bound
    async setPhone(phone: string, phoneOtp: string): Promise<void> {
        await this.apiService.apis.auth.post('users/me/change-phone', {
            phone,
            phoneOtp,
        });

        const { data } = await this.apiService.apis.auth.get<AuthUser>('users/me');
        this.user = data;
    }

    @action.bound
    async generatePkce(): ReturnType<typeof generatePkce> {
        const pkce = await generatePkce();
        window.localStorage.setItem('auth-verifier', pkce.verifier);
        return pkce;
    }

    @action.bound
    @error({
        SCAMMER: {
            redirect: `/account-locked`,
        },
        404: {
            alert: {
                header: 'Account Not Found',
                message: `We could not find an account associated with your email. Did you mean to register?`,
            },
            redirect: '/welcome?step=register',
        },
        409: {
            alert: {
                header: 'Email Already in Use',
                message: `Did you mean to login?`,
            },
            redirect: '/welcome?step=login',
        },
    })
    async handleOauthCallback(): Promise<void> {
        const params = Object.assign({}, this.routerStore.query, JSON.parse(this.routerStore.query.state || '{}'));
        const { sessionToken, code, verifier, action, provider, timeZone, phone, phoneOtp, denied_scopes: deniedScopes } = params;

        if (!verifier || window.localStorage.getItem('auth-verifier') !== verifier) {
            throw new Error('Authorization code verifier is missing or invalid.');
        }

        window.localStorage.removeItem('auth-verifier');

        if (deniedScopes) {
            throw new Error('User denied required scopes.');
        }

        if (!code) {
            throw new Error('Missing oauth code.');
        }

        const oauthData: Record<string, unknown> = {
            redirectUri: `${config.baseUrl}/sso/${provider}`,
            authCode: code,
        };

        if (action === 'register') {
            if (!timeZone) {
                throw new Error('Time zone missing from callback.');
            }
            if (!phoneOtp || !phone) {
                throw new Error('Phone or OTP missing from callback.');
            }

            oauthData.phoneOtp = phoneOtp;
            oauthData.phone = phone;
            oauthData.timeZone = timeZone;
        }

        // ideally this could have happened in the analytics store but we are only
        // allowed to carry data through the oauth process using a custom "state"
        // query string variable so we are unable to leverage our existing sessionToken
        // query string variable like we do for sessions created via the URL service
        if (sessionToken) {
            this.analyticsStore.setSessionToken(sessionToken);
        }

        const { data: authData } = await this.apiService.apis.auth.post<AuthData>(`auth/${provider}/${action}`, oauthData);

        await this.completeAuth(authData, action);
    }

    // TODO: remove ability to pass email to this endpoint once
    // we've fully transitioned to phone-based login
    @action.bound
    async sendPhoneOtp(phoneOrEmail: string, action: 'login' | 'register' | 'change_phone'): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const recaptchaToken = await (window as any).grecaptcha.enterprise.execute(config.recaptchaSiteKey, { action });

        if (phoneOrEmail.includes('@')) {
            await this.apiService.apis.auth.post('otp/phone', { email: phoneOrEmail }, { headers: { 'x-recaptcha-token': recaptchaToken } });
        } else {
            await this.apiService.apis.auth.post('otp/phone', { phone: phoneOrEmail }, { headers: { 'x-recaptcha-token': recaptchaToken } });
        }
    }

    async hasPermission(permissions: string | string[], conditions: PermissionConditions = {}): Promise<boolean> {
        if (!this.user) return false;
        for (const p of [permissions].flat()) {
            if (this.authData?.permissions.includes(p)) {
                // 4th segment of permission name represents the condition
                // i.e. auth.users.get.own
                const [, , , conditionKey] = p.split('.');

                // if permission doesn't have a condition, then check has passed
                if (!conditionKey) return true;

                if (!conditions || !conditions[conditionKey]) {
                    throw new Error('Conditional permission specified without providing a condition handler.');
                }

                const passed = await conditions[conditionKey](this.user);
                if (passed) return true;
            }
        }
        return false;
    }

    @action.bound
    async refreshSession(): Promise<boolean> {
        try {
            const { data: authData } = await this.apiService.apis.auth.post<AuthData>('session/refresh', undefined, { headers: { __allowWhilePaused: '1' } });
            return await this.completeAuth(authData, 'refresh');
        } catch (e) {
            this.logout();
            return false;
        }
    }

    @action.bound
    async checkIfEmailVerified(): Promise<void> {
        const { data } = await this.apiService.apis.auth.get<AuthUser>('users/me');
        this.user = data;
        if (!this.user.emailIsVerified) {
            throw new Error('Email has not been verified');
        }
    }

    @action.bound
    @success((_, email: string) => ({
        toast: {
            message: 'You have been opted-in. Please try to log in again.',
        },
        redirect: `/welcome?step=login&email=${encodeURIComponent(email)}`,
    }))
    @error((_, email: string) => ({
        NOT_OPTED_OUT: {
            alert: {
                message: 'You are already subscribed. Please try logging in again.',
            },
            redirect: `/welcome?step=login&email=${encodeURIComponent(email)}`,
        },
    }))
    async optInParticipant(email: string): Promise<void> {
        await this.apiService.apis.project.post('participant/opt-in', { email });
    }

    @action
    private async completeAuth(authData: AuthData, action: 'login' | 'register' | 'refresh'): Promise<boolean> {
        try {
            if (!authData.roles.includes('participant')) {
                throw new Error('User does not have participant role.');
            }
            this.authData = authData;
            if (!this.user) {
                const { data } = await this.apiService.apis.auth.get<AuthUser>('users/me');
                this.user = data;
            }

            if (action === 'register') {
                this.routerStore.replace(this.user.emailIsVerified ? '/tour' : '/email-verification/pending');
            } else if (action === 'login') {
                this.routerStore.replace('/');
            }

            return true;
        } catch (e) {
            await this.logout();
            return false;
        }
    }
}
