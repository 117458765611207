import { AxiosError } from 'axios';
import { ErrorOption } from 'react-hook-form';

export type ValidationFormError = {
    name: string;
    error: ErrorOption;
};

export type ValidationErrorDetails = {
    type: string;
    path: string;
    message: string;
    errorCode: string;
};

const errorTypeMap: Record<string, string> = {
    required: 'required',
    minimum: 'min',
    maximum: 'max',
    minLength: 'minLength',
    maxLength: 'maxLength',
};

export class ValidationError extends Error {
    readonly formErrors: ValidationFormError[];

    constructor(readonly errors: ValidationErrorDetails[]) {
        super();

        this.formErrors = errors
            .filter((e) => e.type === 'body')
            .map((e, i) => {
                return {
                    name: e.path,
                    error: {
                        type: errorTypeMap[e.errorCode] || 'manual',
                        message: e.message,
                        shouldFocus: i === 0,
                    },
                };
            });
    }
}

export const isValidationError = (error: AxiosError<unknown>): error is AxiosError<{ errors: ValidationErrorDetails[] }> => {
    return (
        [400, 409].includes(error.response?.status || 0) &&
        !!error.response?.data &&
        typeof error.response?.data === 'object' &&
        Array.isArray((error.response?.data as Record<string, unknown>).errors)
    );
};
