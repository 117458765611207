export interface PageImageProps {
    image: string;
}

export const PageImage: React.FC<PageImageProps> = ({ image, children }) => {
    return (
        <div className="uri-page-image">
            <img src={`assets/img/${image}`} />
            {children}
        </div>
    );
};
