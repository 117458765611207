import React, { useCallback, useRef, useState } from 'react';
import { IonButton, IonSlides, IonSlide, IonIcon } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import './Tour.css';
import { UnauthenticatedPage } from '@/Layout';

export const Tour: React.FC = () => {
    const ref = useRef<HTMLIonSlidesElement>(null);
    const [skipVisible, setSkipVisible] = useState(true);

    const onNextSlideClick = useCallback(async () => {
        ref.current?.slideNext();
    }, []);

    const onSlideWillChange = useCallback(async () => {
        ref.current?.isEnd().then((isEnd) => setSkipVisible(!isEnd));
    }, [setSkipVisible]);

    return (
        <UnauthenticatedPage hideSupportLink>
            {skipVisible && (
                <IonButton fill="clear" routerLink="/" style={{ zIndex: 1000, textDecoration: 'none', position: 'absolute', top: 64, right: 24 }}>
                    Skip
                </IonButton>
            )}
            <IonSlides pager ref={ref} onIonSlideWillChange={onSlideWillChange}>
                <IonSlide>
                    <img src="assets/img/tour1.png" alt="Person holding phone" />
                    <h2>Welcome</h2>
                    <p>
                        The URI Participant Portal is where you can participate in studies that interest you, influence product decisions, and get paid for your
                        feedback!
                    </p>
                    <IonButton onClick={onNextSlideClick}>
                        Continue
                        <IonIcon slot="end" icon={arrowForward} />
                    </IonButton>
                </IonSlide>

                <IonSlide>
                    <img src="assets/img/tour2.png" alt="Person browsing on laptop" />
                    <h2>Browse and Apply</h2>
                    <p>
                        Use the URI Participant Portal to quickly apply to studies by completing a simple screening survey. If selected, our coordinators will
                        contact you to participate in the study!
                    </p>
                    <IonButton onClick={onNextSlideClick}>
                        Continue
                        <IonIcon slot="end" icon={arrowForward} />
                    </IonButton>
                </IonSlide>

                <IonSlide>
                    <img src="assets/img/tour3.png" alt="Person participating in research" />
                    <h2>Participate</h2>
                    <p>
                        If you are selected to participate in a study, we&apos;ll set up a time and location to gather your feedback. Your feedback will be used
                        to inform the product&apos;s development!
                    </p>
                    <IonButton onClick={onNextSlideClick}>
                        Continue
                        <IonIcon slot="end" icon={arrowForward} />
                    </IonButton>
                </IonSlide>

                <IonSlide>
                    <img src="assets/img/tour4.png" alt="Person carrying money" />
                    <h2>Get Paid!</h2>
                    <p>After you participate, we will pay you via your preferred payment method as a thank you for your feedback.</p>
                    <IonButton routerLink="/">
                        Let&apos;s get started
                        <IonIcon slot="end" icon={arrowForward} />
                    </IonButton>
                </IonSlide>
            </IonSlides>
        </UnauthenticatedPage>
    );
};
