import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Study } from '../../../stores/StudyStore/types';
import { useStores } from 'stores';
import { AuthenticatedPage } from '@/Layout';
import { StudyListItem } from '../StudyListItem';
import './StudyDetails.css';

export const StudyDetails: React.FC = () => {
    const { authStore, studyStore, participantStore } = useStores();

    const [study, setStudy] = useState<Study>();
    const params = useParams<{ studyId: string }>();

    useEffect(() => {
        // TODO: remove me when we have a better way of fetching single project-participant records
        // and/or can do proper model instance tracking
        if (params.studyId) {
            studyStore.getStudy(params.studyId).then(setStudy);
        }
    }, [authStore, participantStore, studyStore, params.studyId]);

    return (
        <AuthenticatedPage secondaryAction="back" backButtonHref="/studies" backButtonText="Studies" title={study?.name}>
            {study && (
                <div className="study-details-page study-list-item">
                    <StudyListItem study={study} showImage={true} />
                </div>
            )}
        </AuthenticatedPage>
    );
};
