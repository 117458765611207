import { BaseStore } from '../BaseStore/BaseStore';
import type { History, Location, LocationDescriptor } from 'history';
import { action, computed, makeObservable, observable } from 'mobx';
import { RouterRedirect } from './types';
import { RootStores } from 'stores/RootStore/RootStore';
import { RootServices } from 'services/RootService/RootService';

export class RouterStore extends BaseStore {
    @computed get initialized(): boolean {
        return this.location !== undefined;
    }

    @observable.ref location: Location | undefined;

    private redirects: RouterRedirect[] = [];

    history!: History;

    @computed get search(): URLSearchParams {
        return new URLSearchParams(this.location?.search);
    }

    @computed get query(): Record<string, string> {
        return Object.fromEntries([...this.search]);
    }

    constructor() {
        super();
        makeObservable(this);
    }

    onInitialized(stores: RootStores, services: RootServices): void {
        super.onInitialized(stores, services);

        // use '/' as a special path based redirect to /studies or
        // whatever default redirect is in local storage, clearing
        // it if it exists. ideally we would use an observable-based
        // redirect to react to auth status changes, however we currently
        // want to force even logged-in users through the tour and
        // currently don't track whether users have been through it or not.
        this.addRedirect((location, routerStore) => {
            if (location.pathname === '/') {
                location.pathname = routerStore.getDefaultLocation(true) ?? '/studies';
                return location;
            }
        });
    }

    @action.bound
    observeHistory(history: History): History {
        this.history = history;

        this.setLocation(history.location);

        history.listen(this.setLocation);

        return history;
    }

    push(location: LocationDescriptor, state?: unknown): void {
        if (typeof location === 'string') {
            this.history.push(location, state);
        } else {
            this.history.push(location);
        }
    }

    replace(location: LocationDescriptor, state?: unknown): void {
        if (typeof location === 'string') {
            this.history.replace(location, state);
        } else {
            this.history.replace(location);
        }
    }

    removeQueryParam(param: string): string | undefined {
        const value = this.search.get(param) || undefined;
        if (value) {
            this.search.delete(param);
            this.history.replace({ search: this.search.toString() });
        }
        return value;
    }

    go(n: number): void {
        this.history.go(n);
    }

    back(): void {
        this.go(-1);
    }

    forward(): void {
        this.go(1);
    }

    @action.bound
    setLocation(location: Location): void {
        for (const redirect of this.redirects) {
            const pathOrLocation = redirect(location, this);
            if (pathOrLocation) {
                const existingPath = this.history.location.pathname + this.history.location.search;
                const newPath = typeof pathOrLocation === 'string' ? pathOrLocation : (pathOrLocation?.pathname || '') + pathOrLocation?.search;

                if (existingPath !== newPath) {
                    // we have to use setImmediate here to make sure
                    // the replacement gets registered by react-router
                    // since its not expecting a route change during the
                    // same tick as the "listen" event handler.
                    // TODO: check if this is still necessary or this is
                    // a better way after upgrading to react-router 5
                    setImmediate(() => {
                        this.history.replace(pathOrLocation);
                    });

                    return;
                }
            }
        }
        this.location = location;
    }

    addRedirect(redirect: RouterRedirect): void;
    addRedirect(pathOrRegex: string | RegExp, newPathOrLocation: string | Location): void;
    @action.bound
    addRedirect(pathOrRegexOrRedirect: string | RegExp | RouterRedirect, newPathOrLocation?: string | Location): void {
        if (typeof pathOrRegexOrRedirect === 'string') {
            this.redirects.push((location) => location.pathname === pathOrRegexOrRedirect && newPathOrLocation);
        } else if (pathOrRegexOrRedirect instanceof RegExp) {
            this.redirects.push((location) => {
                if (pathOrRegexOrRedirect.test(location.pathname)) {
                    if (typeof newPathOrLocation === 'string') {
                        return location.pathname.replace(pathOrRegexOrRedirect, newPathOrLocation || '');
                    } else if (newPathOrLocation) {
                        newPathOrLocation.pathname = newPathOrLocation.pathname.replace(pathOrRegexOrRedirect, newPathOrLocation.pathname);
                        return newPathOrLocation;
                    }
                }
            });
        } else {
            this.redirects.push(pathOrRegexOrRedirect);
        }
    }

    setDefaultLocation(redirectUrl: string): void {
        window.localStorage.setItem('default-location', redirectUrl);
    }

    getDefaultLocation(clear?: boolean): string | undefined {
        const defaultLocation = window.localStorage.getItem('default-location');
        if (clear) {
            window.localStorage.removeItem('default-location');
        }
        return defaultLocation ?? undefined;
    }
}
