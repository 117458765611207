import React, { useContext } from 'react';
import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonRouterContext, IonText } from '@ionic/react';
import { person, personAdd, logIn, logOut, logoFacebook, logoLinkedin, helpCircle, home, homeSharp } from 'ionicons/icons';
import { Observer } from 'mobx-react-lite';

import { useStores } from 'stores';

import './Menu.css';
import { PortalHeader } from './PortalHeader';
//import { useRouter } from 'utils/hooks';

const routes = {
    loggedInPages: [
        { title: 'Home', path: '/studies', icon: homeSharp },
        { title: 'Profile', path: '/profile', icon: person },
        { title: 'Logout', path: '/logout', icon: logOut },
    ],
    loggedOutPages: [
        { title: 'Login', path: '/welcome?step=login', icon: logIn },
        { title: 'Register', path: '/welcome?step=register', icon: personAdd },
        { title: 'Home', path: '/studies', icon: homeSharp },
    ],
};

export const MenuListItems: React.FC = () => {
    const { authStore } = useStores();
    const { routeInfo } = useContext(IonRouterContext);
    return (
        <Observer>
            {() => {
                const items = authStore.isAuthenticated ? routes.loggedInPages : routes.loggedOutPages;
                return (
                    <IonList lines="none">
                        {items.map(({ title, path, icon }) => {
                            return (
                                <IonItem
                                    key={path}
                                    detail={false}
                                    routerLink={path}
                                    routerDirection="root"
                                    className={routeInfo.pathname.startsWith(path) ? 'selected' : 'inactive'}
                                >
                                    <IonIcon slot="start" icon={icon} />
                                    <IonLabel>{title}</IonLabel>
                                </IonItem>
                            );
                        })}
                    </IonList>
                );
            }}
        </Observer>
    );
};

export const Menu: React.FC = () => {
    const { appStore } = useStores();

    return (
        <Observer>
            {() => {
                return (
                    <IonMenu type="overlay" disabled={!appStore.menuEnabled} contentId="main">
                        <PortalHeader />
                        <IonContent forceOverscroll={false}>
                            <IonMenuToggle auto-hide="false">
                                <MenuListItems />
                                <IonList lines="none">
                                    <IonListHeader>
                                        <IonLabel>Additional Links</IonLabel>
                                    </IonListHeader>
                                    <IonItem detail={false} href="https://www.uriux.com" target="_blank">
                                        <IonIcon slot="start" icon={home} />
                                        <IonLabel>URI Home</IonLabel>
                                    </IonItem>
                                    <IonItem detail={false} href="https://www.uriux.com/participant-portal/help-center/" target="_blank">
                                        <IonIcon slot="start" icon={helpCircle} />
                                        <IonLabel>Help Center</IonLabel>
                                    </IonItem>
                                    <IonItem detail={false} href="https://www.linkedin.com/company/user-research-international" target="_blank">
                                        <IonIcon slot="start" icon={logoLinkedin} />
                                        <IonLabel>URI on LinkedIn</IonLabel>
                                    </IonItem>
                                    <IonItem detail={false} href="https://www.facebook.com/UserResearchInternational" target="_blank">
                                        <IonIcon slot="start" icon={logoFacebook} />
                                        <IonLabel>URI on Facebook</IonLabel>
                                    </IonItem>
                                </IonList>
                                <div className="uriux-footer">
                                    <a href="https://www.uriux.com" target="_blank" rel="noreferrer">
                                        <IonImg src="assets/img/uriux-logo-grey.svg" alt="User Research International" className="uriux-footer-logo" />
                                    </a>
                                    <IonText color="light" className="uriux-footer-copy">
                                        ©{new Date().getFullYear()} User Research International, LLC.
                                        <br />
                                        <a href="https://www.uriux.com/privacy" target="_blank" rel="noreferrer">
                                            Privacy Policy
                                        </a>
                                        {' and '}
                                        <a href="https://www.uriux.com/tos" target="_blank" rel="noreferrer">
                                            Terms of Service
                                        </a>
                                    </IonText>
                                </div>
                            </IonMenuToggle>
                        </IonContent>
                    </IonMenu>
                );
            }}
        </Observer>
    );
};
