import { IonAlert } from '@ionic/react';
import { action, makeObservable, observable } from 'mobx';
import React from 'react';

export type AlertType = 'info' | 'error' | 'success';
export type AlertProps = React.ComponentProps<typeof IonAlert>;
export type Alert = Omit<AlertProps, 'isOpen'> | string;

const alertDefaultsByType: Record<AlertType, Partial<Exclude<Alert, string>>> = {
    info: {
        buttons: ['OK'],
    },
    success: {
        buttons: ['OK'],
    },
    error: {
        buttons: ['OK'],
    },
};

export class AlertStore {
    @observable alert: AlertProps | undefined;

    constructor() {
        makeObservable(this);
    }

    @action
    show(type: AlertType, alertOrMessage: Alert): void {
        const alert = typeof alertOrMessage === 'string' ? { message: alertOrMessage } : alertOrMessage;
        this.alert = {
            ...alertDefaultsByType[type],
            ...alert,
            isOpen: true,
            onDidDismiss: action(() => {
                this.alert = undefined;
            }),
        };
    }
}
