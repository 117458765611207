import { Route, Redirect } from 'react-router-dom';
import { rootStoresContext } from 'stores';

export class UnauthenticatedRoute extends Route {
    render(): React.ReactNode {
        return (
            <rootStoresContext.Consumer>
                {({ authStore }) => {
                    if (authStore.isAuthenticated) {
                        return <Redirect to={'/'} />;
                    }
                    return super.render();
                }}
            </rootStoresContext.Consumer>
        );
    }
}
