import { IonItem, IonLabel, IonText } from '@ionic/react';
import { UnauthenticatedPage } from '@/Layout';
import { EmailVerificationErrorForm } from './EmailVerificationErrorForm';
import { useQueryParams, useUnauthenticatedPage } from 'utils';

export const EmailVerificationError: React.FC = () => {
    useUnauthenticatedPage();
    const qs = useQueryParams();
    return (
        <UnauthenticatedPage image="email-verification-error.png" className="ion-text-center">
            <IonText color="secondary">
                <h3>We could not verify your account.</h3>
            </IonText>
            <p>Click below to request a new verification link.</p>
            <EmailVerificationErrorForm email={qs.get('email') || ''} />
            <IonItem lines="none" detail={false} routerLink="/welcome?step=login" routerDirection="root" className="ion-text-center">
                <IonLabel color="secondary">Login</IonLabel>
            </IonItem>
        </UnauthenticatedPage>
    );
};
