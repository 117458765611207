import { IonLabel, IonList, IonListHeader } from '@ionic/react';
import { Observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { Blurb } from '@/Generic';
import React, { createRef, useCallback } from 'react';
import { Form, Checkbox } from '@/Form';
import { useMountEffect, usePromiseHandler } from 'utils';
import { UserPreferences } from '../../../stores/CommunicationPreferencesStore/types';
import { AuthenticatedPage } from '@/Layout';
import { chatbox } from 'ionicons/icons';

export const CommunicationPreferences: React.FC = () => {
    const formRef = createRef<HTMLFormElement>();
    const { communicationPreferencesStore } = useStores();

    // we take extra precaution here to always force a reload of the preferences
    // since subscription/unsubcription can also happen asyncronously via email
    // TODO: remove this when lazyObservable can reset automatically on unobserve
    useMountEffect(() => {
        communicationPreferencesStore.userPreferences.reload();
    });

    const onSubmit = useCallback<(data: UserPreferences) => Promise<void>>(
        async (data) => {
            return communicationPreferencesStore.updatePreferences(data);
        },
        [communicationPreferencesStore]
    );

    const handleSubmit = usePromiseHandler(onSubmit, {
        success: {
            type: 'toast',
            message: 'Your communication preferences have been saved.',
        },
        error: {
            type: 'toast',
            message: 'Failed saving communication preferences.',
        },
    });

    return (
        <Observer>
            {() => {
                const { emailTemplatesByGroup, userPreferences } = communicationPreferencesStore;

                return (
                    <AuthenticatedPage backButtonHref="/profile" backButtonText="Profile" loading={userPreferences.loading}>
                        <div>
                            <Blurb title="Communication Preferences" icon={chatbox}>
                                Select the subscriptions you would like to receive.
                            </Blurb>
                            <Form ref={formRef} handleSubmit={handleSubmit} submitButton="Update Preferences">
                                <IonList>
                                    {Object.entries(emailTemplatesByGroup).map(([groupName, templates]) => (
                                        <React.Fragment key={groupName}>
                                            <IonListHeader>
                                                <IonLabel>{groupName}</IonLabel>
                                            </IonListHeader>
                                            {templates.map((template) => (
                                                <Checkbox
                                                    key={template.id}
                                                    label={template.name}
                                                    name={`emailPreferences.${template.id}.subscribed`}
                                                    defaultChecked={
                                                        userPreferences.value()?.emailPreferences[template.id]?.subscribed ??
                                                        template.defaultPreferences.subscribed
                                                    }
                                                />
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </IonList>
                            </Form>
                        </div>
                    </AuthenticatedPage>
                );
            }}
        </Observer>
    );
};
