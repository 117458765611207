import { ErrorPage } from '@/Layout/ErrorPage';
import { IonButton } from '@ionic/react';

export const Error: React.FC = () => (
    <ErrorPage>
        <p>
            Need help? <a href="mailto:portalsupport@uriux.com?subject=Support">contact support</a>.
        </p>
        <IonButton color="primary" routerLink="/studies" routerDirection="root">
            Home
        </IonButton>
    </ErrorPage>
);
