import { IonContent, IonImg, IonPage } from '@ionic/react';
import React from 'react';
import './Preloader.css';

export const Preloader: React.FC = () => (
    <IonPage>
        <IonContent fullscreen>
            <div
                style={{
                    height: '100%',
                    padding: '0',
                    margin: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="box">
                    <IonImg src="assets/icon/favicon.png" style={{ width: '80px', height: '80px' }} alt="User Research International" />
                </div>
            </div>
        </IonContent>
    </IonPage>
);

export default Preloader;
