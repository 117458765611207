import { IonButton, IonIcon, useIonAlert } from '@ionic/react';
import { logoFacebook, logoLinkedin } from 'ionicons/icons';
import { useCallback } from 'react';
import { useStores } from '../../stores';

export interface SsoButtonProps extends React.ComponentProps<typeof IonButton> {
    action: 'login' | 'register';
    provider: 'facebook' | 'linkedin';
    phone?: string;
    phoneOtp?: string;
}

export const SsoButton: React.FC<SsoButtonProps> = ({ children, provider, action, phone, phoneOtp, ...buttonProps }) => {
    const { authStore } = useStores();

    const [present] = useIonAlert();

    const onClick = useCallback(() => {
        if (action === 'register') {
            present({
                cssClass: 'uriux-alert-terms',
                header: 'Agree to Terms?',
                message: 'Before we continue, you must agree to the Privacy Policy and Terms of Service.',
                buttons: [
                    {
                        text: 'Privacy Policy',
                        handler: () => {
                            window.open('https://www.uriux.com/privacy/', '_blank');
                            return false;
                        },
                    },
                    {
                        text: 'Terms of Service',
                        handler: () => {
                            window.open('https://www.uriux.com/tos/', '_blank');
                            return false;
                        },
                    },
                    {
                        text: 'I Agree',
                        handler: () => {
                            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            if (provider === 'facebook') {
                                authStore.oauthViaFacebook('register', timeZone, phone, phoneOtp);
                            } else if (provider === 'linkedin') {
                                authStore.oauthViaLinkedin('register', timeZone, phone, phoneOtp);
                            }
                        },
                    },
                ],
            });
        } else {
            if (provider === 'facebook') {
                authStore.oauthViaFacebook('login');
            } else if (provider === 'linkedin') {
                authStore.oauthViaLinkedin('login');
            }
        }
    }, [action, provider, present, phone, phoneOtp, authStore]);

    const icon = provider === 'facebook' ? logoFacebook : logoLinkedin;

    return (
        <IonButton expand="block" color={provider} onClick={onClick} {...buttonProps}>
            {children} &nbsp;<IonIcon icon={icon}></IonIcon>
        </IonButton>
    );
};
