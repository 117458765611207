import { AppStore } from '../AppStore';
import { RootService, RootServices } from '../../services/RootService';
import { StudyStore } from '../StudyStore';
import { ParticipantStore } from '../ParticipantStore';
import { AuthStore } from '../AuthStore';
import { LoaderStore } from '../LoaderStore/LoaderStore';
import { ToastStore } from '../ToastStore/ToastStore';
import { AlertStore } from '../AlertStore/AlertStore';
import { RouterStore } from '../RouterStore/RouterStore';
import { StorageStore } from 'stores/StorageStore';
import { AnalyticsStore } from 'stores/AnalyticsStore';
import { ModalStore } from 'stores';
import { CommunicationPreferencesStore } from '../CommunicationPreferencesStore';

export interface RootStores {
    alertStore: AlertStore;
    appStore: AppStore;
    routerStore: RouterStore;
    authStore: AuthStore;
    analyticsStore: AnalyticsStore;
    loaderStore: LoaderStore;
    participantStore: ParticipantStore;
    storageStore: StorageStore;
    studyStore: StudyStore;
    communicationPreferencesStore: CommunicationPreferencesStore;
    toastStore: ToastStore;
    modalStore: ModalStore;
}

export interface OnInitialized {
    onInitialized(rootStores: RootStores, rootServices: RootServices): void;
}

export class RootStore {
    readonly stores: RootStores;

    constructor() {
        this.stores = {
            loaderStore: new LoaderStore(),
            toastStore: new ToastStore(),
            alertStore: new AlertStore(),
            modalStore: new ModalStore(),
            routerStore: new RouterStore(),
            analyticsStore: new AnalyticsStore(),
            appStore: new AppStore(),
            authStore: new AuthStore(),
            storageStore: new StorageStore(),
            studyStore: new StudyStore(),
            participantStore: new ParticipantStore(),
            communicationPreferencesStore: new CommunicationPreferencesStore(),
        };

        const rootService = new RootService();

        this.stores.loaderStore.loadBegin('preload', { delay: 0 });

        for (const store of Object.values(this.stores)) {
            if (isInitializable(store)) {
                store.onInitialized(this.stores, rootService.services);
            }
        }

        this.stores.loaderStore.loadEnd('preload');
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isInitializable = (arg: any): arg is OnInitialized => {
    return arg.onInitialized !== undefined;
};
