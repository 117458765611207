import { IonModal } from '@ionic/react';
import { Observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useStores } from 'stores';

export const Modals: FC = (): JSX.Element => {
    const { modalStore } = useStores();
    return (
        <Observer>
            {() => (
                <>
                    {modalStore.modals.map((modal) => {
                        const Component = modal.options.component;
                        return (
                            <IonModal key={modal.id} keyboardClose={false} swipeToClose={true} isOpen={!modal.hidden}>
                                <Component {...modal.options.componentProps} />
                            </IonModal>
                        );
                    })}
                </>
            )}
        </Observer>
    );
};
