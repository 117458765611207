import React from 'react';
import { UnauthenticatedPage } from '@/Layout';
import { RequestPasswordResetForm } from './RequestPasswordResetForm';
import { useQueryParams, useUnauthenticatedPage } from 'utils';

export const RequestPasswordReset: React.FC = () => {
    useUnauthenticatedPage();
    const qs = useQueryParams();
    return (
        <UnauthenticatedPage title="Password Reset Request" image="password-reset.png">
            <p>Enter your email to receive a link to reset your password.</p>
            <RequestPasswordResetForm email={qs.get('email') || ''} />
        </UnauthenticatedPage>
    );
};
