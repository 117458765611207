import { IonIcon } from '@ionic/react';

export interface BlurbProps {
    icon: string;
    title?: string;
    subtitle?: string;
    style?: React.CSSProperties;
}

export const Blurb: React.FC<BlurbProps> = ({ children, icon, title, subtitle, style = {} }) => {
    return (
        <div style={{ marginTop: 24, marginBottom: 24, display: 'grid', gridTemplateColumns: '48px 1fr', columnGap: '16px', ...style }}>
            <IonIcon icon={icon} color="primary" size="large" style={{ justifySelf: 'center' }} />
            <div>
                {title && <h2 style={{ marginTop: 0, padding: '0' }}>{title}</h2>}
                {subtitle && <h4 style={{ marginTop: 0, marginBottom: 5 }}>{subtitle}</h4>}
                {children}
            </div>
        </div>
    );
};
