import { PageImage } from '@/Generic';
import { AuthenticatedPage } from '@/Layout';
import { IonItem, IonItemGroup, IonLabel, IonList, IonSegment, IonSegmentButton } from '@ionic/react';
import { Observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useStores } from 'stores';
import { StudyListItem } from '../../studies/StudyListItem';
import { RefresherEventDetail } from '@ionic/core';
import { useMountEffect } from 'utils';

export const StudyHistory: React.FC = () => {
    const { authStore, studyStore, participantStore } = useStores();
    const [segmentSelection, setSegmentSelection] = useState('incomplete');

    const handleRefresh = useCallback<(e: CustomEvent<RefresherEventDetail>) => void>(
        (e) => {
            if (authStore.user) {
                participantStore.studyApplications.reload().then(() => {
                    e.detail.complete();
                });
            }
        },
        [authStore, participantStore]
    );

    useMountEffect(() => {
        participantStore.studyApplications.reload();
    });

    return (
        <AuthenticatedPage
            backButtonHref="/profile"
            backButtonText="Back"
            title="History"
            secondaryAction="back"
            handleRefresh={handleRefresh}
            collapsibleTitle={false}
        >
            <Observer>
                {() => {
                    const { incompleteApplications, completedApplications, participatedStudies } = studyStore;
                    let studies = participatedStudies;
                    let blurb = 'Below are surveys you started but did not finish.  Click "Continue" to pick up where you left off.';
                    if (segmentSelection === 'incomplete') {
                        studies = incompleteApplications;
                    } else if (segmentSelection === 'completed') {
                        studies = completedApplications;
                        blurb = 'Below are surveys you have completed.';
                    } else if (segmentSelection === 'participated') {
                        studies = participatedStudies;
                        blurb = 'Below are studies you participated in.';
                    }
                    return (
                        <>
                            <IonSegment scrollable={true} value={segmentSelection} onIonChange={(e) => setSegmentSelection(e.detail.value || 'incomplete')}>
                                <IonSegmentButton value="incomplete">
                                    <IonLabel>Incomplete</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="completed">
                                    <IonLabel>Completed</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="participated">
                                    <IonLabel>Participated</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                            <IonList style={{ padding: '0 10px' }}>
                                <div className="blurb">{blurb}</div>
                                {!!studies.length ? (
                                    <IonItemGroup>
                                        {studies.map((study) => (
                                            <IonItem key={study.id} lines="none" detail={false} className="study-list-item">
                                                <StudyListItem key={study.id} study={study} />
                                            </IonItem>
                                        ))}
                                    </IonItemGroup>
                                ) : (
                                    <PageImage image="studies-empty.png">Nothing found.</PageImage>
                                )}
                            </IonList>
                        </>
                    );
                }}
            </Observer>
        </AuthenticatedPage>
    );
};
