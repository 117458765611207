import { PageImage } from '@/Generic';
import { IonContent, IonHeader, IonToolbar, IonMenuButton, IonButton, IonPage } from '@ionic/react';
import classNames from 'classnames';

export type UnauthenticatedPageProps = {
    className?: string;
    title?: string;
    image?: string;
    subtitle?: string;
    hideSupportLink?: boolean;
};

export const UnauthenticatedPage: React.FC<UnauthenticatedPageProps> = ({ title, className, image, subtitle, hideSupportLink, children }) => {
    return (
        <IonPage className={classNames('uri-page unauthenticated', className)}>
            <IonHeader>
                <IonToolbar>
                    <IonMenuButton slot="start" />
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                {title && <h1>{title}</h1>}
                {image && <PageImage image={image} />}
                {subtitle && <h2>{title}</h2>}
                {children}
                <div className="uri-support-link">
                    The data collected by the use of this website may be shared with third parties to provide a secure and reliable service.
                </div>
                <div className="uri-support-link">
                    {hideSupportLink ? (
                        <>
                            This site is protected by reCAPTCHA. The Google&nbsp;
                            <a target="_blank" href="https://policies.google.com/privacy" rel="noreferrer">
                                Privacy Policy
                            </a>
                            &nbsp;and&nbsp;
                            <a target="_blank" href="https://policies.google.com/terms" rel="noreferrer">
                                Terms of Service
                            </a>
                            &nbsp;apply.
                        </>
                    ) : (
                        <IonButton fill="clear" href="https://www.uriux.com/participant-portal/help-center/" target="_blank" size="small">
                            Need help?
                        </IonButton>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};
