import { Form, InputEmail } from '@/Form';
import { Divider } from '@/Generic';
import { useOtpModal } from '@/Misc';
import { SsoButton } from '@/Sso';
import { IonButton, IonList } from '@ionic/react';
import { useCallback } from 'react';
import { useStores } from 'stores';
import { usePromiseHandler } from 'utils';
import { Progress, SetProgress } from './Welcome';

export const LoginEmail: React.FC<{ progress: Progress; setProgress: SetProgress }> = ({ progress, setProgress }) => {
    const { authStore } = useStores();

    const [showOtpModal] = useOtpModal({
        action: 'login',
        loadingMessage: 'Logging in...',
        handleSubmit: ({ phone, otp }) => authStore.loginViaPhone(phone, otp),
    });

    const handleOtpSend = usePromiseHandler(authStore.sendPhoneOtp, {
        success: (_, phone) => ({
            type: 'custom',
            handler: () => {
                showOtpModal(phone);
            },
        }),
        error: {
            type: 'alert',
            message: 'Unable to send code to number.',
        },
        errorCodes: {
            401: {
                type: 'custom',
                handler: () => {
                    setProgress((p) => ({ ...p, step: 'login-password' }));
                },
            },
            404: {
                type: 'alert',
                message: 'No account exists with the specified email. Please register to continue.',
                handler: () => {
                    setProgress({ step: 'register' });
                },
            },
            429: {
                type: 'alert',
                message: 'We are experiencing higher than usual activity. Please wait a few minutes and try again.',
            },
        },
    });

    const handleSubmit = useCallback(
        ({ email }: { email: string }) => {
            setProgress((p) => ({ ...p, email }));
            return handleOtpSend(email, 'login');
        },
        [handleOtpSend, setProgress]
    );

    return (
        <>
            <SsoButton action="login" provider="facebook">
                Login with Facebook
            </SsoButton>
            {/*<SsoButton action="login" provider="linkedin">
                Login with LinkedIn
            </SsoButton>
            */}
            <Divider>or Login with Email</Divider>
            <Form handleSubmit={handleSubmit} submitButton="Continue">
                <IonList>
                    <InputEmail name="email" defaultValue={progress.email} />
                </IonList>
                <br />
            </Form>
            <IonButton fill="clear" size="small" expand="full" onClick={() => setProgress({ step: 'register' })}>
                Don&apos;t have an account?
            </IonButton>
        </>
    );
};
