import { IonToast } from '@ionic/react';
import { action, makeObservable, observable } from 'mobx';
import React from 'react';

export type ToastType = 'info' | 'success' | 'error' | 'warning';
export type ToastProps = React.ComponentProps<typeof IonToast>;
export type Toast = Omit<ToastProps, 'isOpen'> | string;

const toastDefaultsByType: Record<ToastType, Partial<Exclude<Toast, string>>> = {
    info: {
        color: 'secondary',
        duration: 5000,
    },
    success: {
        color: 'secondary',
        duration: 5000,
    },
    error: {
        color: 'secondary',
        duration: 5000,
    },
    warning: {
        color: 'secondary',
        duration: 5000,
    },
};

export class ToastStore {
    @observable toast: ToastProps | undefined;

    constructor() {
        makeObservable(this);
    }

    @action
    show(type: ToastType, toastOrMessage: Toast): void {
        const toast = typeof toastOrMessage === 'string' ? { message: toastOrMessage } : toastOrMessage;
        this.toast = {
            ...toastDefaultsByType[type],
            ...toast,
            isOpen: true,
            onDidDismiss: action(() => {
                this.toast = undefined;
            }),
        };
    }
}
