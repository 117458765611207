import { IonButton } from '@ionic/react';
import { Progress, SetProgress } from './Welcome';

export const WelcomeStart: React.FC<{ progress: Progress; setProgress: SetProgress }> = ({ setProgress }) => {
    return (
        <>
            <IonButton color="primary" expand="block" onClick={() => setProgress({ step: 'register' })}>
                Register
            </IonButton>
            <IonButton color="primary" expand="block" onClick={() => setProgress({ step: 'login' })}>
                Login
            </IonButton>
            <IonButton fill="clear" routerLink="/tour" size="small" expand="full">
                Browse Studies
            </IonButton>
        </>
    );
};
