import { IAutorunOptions, IReactionPublic, reaction } from 'mobx';
import { useEffect } from 'react';

export function useReaction<R = unknown>(
    expression: (r: IReactionPublic) => R,
    effect: (arg1: R, arg2: R, r: IReactionPublic) => unknown,
    options?: IAutorunOptions
): void {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => reaction(expression, effect, options), []);
}
