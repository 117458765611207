import { makeObservable, observable, action, computed } from 'mobx';

export interface Loader {
    count: number;
    message: string;
    timeoutHandle?: number;
    delayHandle?: number;
}

export type LoaderOptions = {
    message?: string;
    timeout?: number;
    delay?: number;
    min?: number;
};

export class LoaderStore {
    @observable private loaders = new Map<string, Loader>();
    private delayedLoaders = new Map<string, Loader>();

    @computed get loading(): boolean {
        return this.loaders.size > 0;
    }

    @computed get loadingMessage(): string {
        const loader = this.loaders.values().next();
        return loader.done ? '' : loader.value.message;
    }

    constructor() {
        makeObservable(this);
    }

    getLoadStatus(key: string): boolean {
        return this.loaders.has(key);
    }

    @action
    loadBegin(key: string, { message = 'Loading...', timeout = 6000, delay = 150, min = 0 }: LoaderOptions = {}): void {
        let loader = this.loaders.get(key);
        let loaderWasCreated = false;

        if (!loader) {
            loader = {
                count: 0,
                message,
            };
            loaderWasCreated = true;
        }

        clearTimeout(loader.timeoutHandle);
        loader.timeoutHandle = this.setTimeout(key, timeout);

        loader.count++;

        if (loader.count === 1 && delay) {
            loader.delayHandle = this.setDelay(key, delay);
            this.delayedLoaders.set(key, loader);
        } else {
            this.loaders.set(key, loader);
        }

        if (loaderWasCreated && min) {
            this.loadBegin(key);
            setTimeout(() => {
                this.loadEnd(key);
            }, min);
        }
    }

    @action
    loadEnd(key: string): void {
        const loader = this.findLoader(key);
        if (!loader) {
            console.log(`Loader '${key}' not active. Are you forgetting to call loadBegin() or calling loadEnd() too many times?`);
            return;
        }

        loader.count--;

        if (!loader.count) {
            this.deleteLoader(key);
        }
    }

    @action
    loadUpdate(key: string, { message = 'Loading...', timeout = 5000 }: LoaderOptions): void {
        const loader = this.findLoader(key);
        if (!loader) {
            console.log(`Loader '${key}' not active. Are you forgetting to call loadBegin() or calling loadEnd() too many times?`);
            return;
        }

        clearTimeout(loader.timeoutHandle);
        loader.timeoutHandle = this.setTimeout(key, timeout);

        this.loaders.set(key, {
            ...loader,
            message,
        });
    }

    private findLoader(key: string): Loader | undefined {
        return this.delayedLoaders.get(key) || this.loaders.get(key);
    }

    private deleteLoader(key: string) {
        const loader = this.findLoader(key);
        clearTimeout(loader?.delayHandle);
        clearTimeout(loader?.timeoutHandle);
        this.delayedLoaders.delete(key);
        this.loaders.delete(key);
    }

    private setTimeout(key: string, timeout: number): number {
        return setTimeout(
            action(() => {
                this.loaders.delete(key);
                console.log(`Loader '${key}' timed out. Did you forget to call loadEnd()?`);
            }),
            timeout
        ) as unknown as number;
    }

    private setDelay(key: string, delay: number): number {
        return setTimeout(
            action(() => {
                const loader = this.delayedLoaders.get(key);
                if (loader) {
                    this.loaders.set(key, loader);
                    this.delayedLoaders.delete(key);
                }
            }),
            delay
        ) as unknown as number;
    }
}
