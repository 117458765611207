import React, { useCallback } from 'react';
import { IonList } from '@ionic/react';
import { useStores } from 'stores';
import { Form, InputPassword } from '@/Form';
import { usePromiseHandler } from 'utils';

type CompletePasswordResetFormData = {
    email: string;
    password: string;
    confirmPassword: string;
    resetCode: string;
};

export const CompletePasswordResetForm: React.FC<{ email: string; resetCode: string }> = (props) => {
    const { authStore } = useStores();

    const onSubmit = useCallback<(data: CompletePasswordResetFormData) => Promise<void>>(
        ({ password }) => authStore.completePasswordReset(props.email, password, props.resetCode),
        [authStore, props.email, props.resetCode]
    );

    const handleSubmit = usePromiseHandler(onSubmit, {
        success: {
            type: 'toast',
            message: 'Your password has been reset. You may now login.',
            redirect: [`/welcome?step=login&email=${encodeURIComponent(props.email)}`, 'forward', 'replace'],
        },
        error: {
            type: 'alert',
            header: 'Password Reset Failed',
            message: 'Please try requesting a new reset email or contact support.',
            redirect: [`/password-reset/request?email=${encodeURIComponent(props.email)}`, 'none', 'replace'],
        },
    });

    return (
        <Form handleSubmit={handleSubmit} submitButton="Reset Password">
            <IonList>
                <InputPassword required name="password" autoComplete="new-password" autoFocus />
                <InputPassword match="password" autoComplete="new-password" required name="confirmPassword" />
            </IonList>
        </Form>
    );
};
