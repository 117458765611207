import {
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonMenuButton,
    IonIcon,
    IonFooter,
    IonButton,
    IonRefresher,
    IonRefresherContent,
    IonPage,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { RefresherEventDetail } from '@ionic/core';
import classNames from 'classnames';

export type AuthenticatedPageProps = {
    title?: string;
    className?: string;
    loading?: boolean;
    secondaryAction?: 'none' | 'menu' | 'back' | 'close';
    primaryAction?: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handlePrimaryAction?: (data?: any) => any;
    handleRefresh?: (e: CustomEvent<RefresherEventDetail>) => void;
    handleClose?: () => void;
    collapsibleTitle?: boolean;
    removeMargin?: boolean;
    backButtonHref?: string;
    backButtonText?: string;
    footer?: React.ReactElement;
};

export const AuthenticatedPage: React.FC<AuthenticatedPageProps> = ({
    title,
    loading,
    collapsibleTitle,
    removeMargin = false,
    primaryAction,
    handlePrimaryAction,
    secondaryAction = 'back',
    backButtonHref,
    backButtonText = 'Back',
    handleRefresh,
    className,
    handleClose,
    footer,
    children,
}) => {
    return (
        <IonPage className={classNames('uri-page authenticated', className)}>
            <IonHeader>
                <IonToolbar>
                    {secondaryAction !== 'none' && (
                        <IonButtons slot="start">
                            {secondaryAction === 'back' && <IonBackButton defaultHref={backButtonHref} text={backButtonText} />}
                            {secondaryAction === 'close' && (
                                <IonButton onClick={handleClose} size="large">
                                    <IonIcon slot="icon-only" icon={closeOutline} />
                                </IonButton>
                            )}
                            {secondaryAction === 'menu' && <IonMenuButton color="secondary" />}
                        </IonButtons>
                    )}
                    <IonTitle color="primary">{title}</IonTitle>
                    {primaryAction && (
                        <IonButtons slot="end">
                            <IonButton onClick={handlePrimaryAction}>{primaryAction}</IonButton>
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className={removeMargin ? 'no-padding' : ''}>
                {handleRefresh && (
                    <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                )}
                {collapsibleTitle && (
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle color="primary" size="large">
                                {title}
                            </IonTitle>
                        </IonToolbar>
                    </IonHeader>
                )}
                {loading ? null : children}
            </IonContent>
            {footer && (
                <IonFooter>
                    <IonToolbar>{footer}</IonToolbar>
                </IonFooter>
            )}
        </IonPage>
    );
};
