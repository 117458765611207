import { useStores } from 'stores';
import { Observer } from 'mobx-react-lite';
import { RefresherEventDetail } from '@ionic/core';
import { AuthenticatedPage } from '@/Layout';
import { useCallback } from 'react';
import { IonCheckbox, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonList, useIonModal } from '@ionic/react';
import { StudyListItem } from './StudyListItem';
import { PageImage } from '@/Generic';
import { options, refresh } from 'ionicons/icons';
import { Profile, studyTypes } from 'stores/StudyStore/types';
import './Studies.css';
import { getStudyDisplayType } from './helpers';
import { useMountEffect, usePromiseHandler } from 'utils';

export const Studies: React.FC = () => {
    const { appStore, authStore, routerStore, studyStore, participantStore } = useStores();
    const { profiles } = studyStore;

    const handleRefresh = useCallback<(e?: CustomEvent<RefresherEventDetail>) => void>(
        (e) => {
            if (authStore.user) {
                Promise.all([studyStore.activeStudies.reload(), participantStore.studyApplications.reload()]).then(() => {
                    if (e) e.detail.complete();
                });
            } else {
                studyStore.activeStudies.reload().then(() => {
                    if (e) e.detail.complete();
                });
            }
        },
        [authStore, studyStore, participantStore]
    );

    const manualRefresh = usePromiseHandler(() => studyStore.activeStudies.reload(), {
        success: {
            type: 'toast',
            message: 'Refresh complete.',
        },
    });

    useMountEffect(() => {
        studyStore.activeStudies.reload();
    });

    const StudyFilters: React.FC<{ profiles: Profile[] }> = ({ profiles }) => {
        return (
            <div className="study-filters-wrapper">
                <div className="study-filters">
                    <div className="filter-title">Profile</div>
                    {profiles.map((p) => (
                        <IonItem key={p.id} lines="none" className="filter-wrapper">
                            <IonCheckbox
                                name={p.name}
                                className="filter-checkbox"
                                checked={studyStore.profileFilters.includes(p.id)}
                                onIonChange={(e) => handleProfileChange(e, p.id)}
                            ></IonCheckbox>
                            <IonLabel className="filter-label">{p.name}</IonLabel>
                        </IonItem>
                    ))}
                </div>
                <div className="study-filters">
                    <div className="filter-title">Study Type</div>
                    {studyTypes.map((st) => (
                        <IonItem key={st} lines="none" className="filter-wrapper">
                            <IonCheckbox
                                name={st}
                                className="filter-checkbox"
                                checked={studyStore.methodologyFilters.includes(st)}
                                onIonChange={(e) => handleMethodologyChange(e, st)}
                            ></IonCheckbox>
                            <IonLabel className="filter-label">{getStudyDisplayType(st)}</IonLabel>
                        </IonItem>
                    ))}
                </div>
            </div>
        );
    };

    const StudyFilterModal: React.FC<{ profiles: Profile[]; handleClose: () => void }> = ({ profiles, handleClose }) => {
        return (
            <AuthenticatedPage title="Study Filters" primaryAction="Apply" handlePrimaryAction={() => handleClose()}>
                <StudyFilters profiles={profiles} />
            </AuthenticatedPage>
        );
    };

    const handleSearchTermChange = (e: { detail: { value: string | null | undefined }; preventDefault: () => void }) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        studyStore.setSearchTerm(e.detail.value!);
    };

    const handleProfileChange = (e: { detail: { checked: boolean }; preventDefault: () => void }, profileId: number) => {
        studyStore.setProfileFilter(e.detail.checked, profileId);
    };

    const handleMethodologyChange = (e: { detail: { checked: boolean }; preventDefault: () => void }, methodology: string) => {
        studyStore.setMethodologyFilter(e.detail.checked, methodology);
    };

    const [showFilterModal, hideFilterModal] = useIonModal(StudyFilterModal, {
        profiles: profiles.value(),
        handleClose: () => {
            hideFilterModal();
        },
    });

    return (
        <Observer>
            {() => {
                const appliedFiltersCount = studyStore.profileFilters.length + studyStore.methodologyFilters.length;
                return (
                    <AuthenticatedPage
                        title="Home"
                        primaryAction={authStore.user ? 'History' : ''}
                        handlePrimaryAction={authStore.user ? () => routerStore.push('/profile/history') : () => <></>}
                        secondaryAction="menu"
                        handleRefresh={handleRefresh}
                        collapsibleTitle={false}
                        removeMargin={true}
                    >
                        <div className="study-list-wrapper">
                            {appStore.desktopView && (
                                <div className="study-list-filters">
                                    <StudyFilters profiles={profiles.value()} />
                                </div>
                            )}
                            <div className="studies-list">
                                <IonList>
                                    <IonItemGroup>
                                        <IonItem lines="none" detail={false} className="search-filter">
                                            <div className="search-wrapper">
                                                <IonInput
                                                    clearInput={true}
                                                    type="text"
                                                    placeholder="Search..."
                                                    className="search-input"
                                                    value={studyStore.searchTerm}
                                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                    onIonChange={(e) => handleSearchTermChange(e)}
                                                ></IonInput>
                                                <div className="filter-icon" onClick={() => showFilterModal()}>
                                                    <IonIcon icon={options} />
                                                    {appliedFiltersCount > 0 && <div className="applied-filters-count">{appliedFiltersCount}</div>}
                                                </div>
                                            </div>
                                            <div className="refresh-icon" onClick={() => manualRefresh()}>
                                                <IonIcon icon={refresh} />
                                            </div>
                                        </IonItem>
                                    </IonItemGroup>

                                    {!studyStore.sortedStudies.length ? (
                                        <PageImage image="studies-empty.png">No studies found.</PageImage>
                                    ) : (
                                        <IonItemGroup>
                                            {studyStore.sortedStudies.map((study) => (
                                                <IonItem key={study.id} lines="none" detail={false} className="study-list-item">
                                                    <StudyListItem key={study.id} study={study} showImage={study.featured} />
                                                </IonItem>
                                            ))}
                                        </IonItemGroup>
                                    )}
                                </IonList>
                            </div>
                        </div>
                    </AuthenticatedPage>
                );
            }}
        </Observer>
    );
};
