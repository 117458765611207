import { IonSplitPane, IonLoading, IonToast, IonAlert, IonApp } from '@ionic/react';
import { History } from 'history';
import { IonReactRouter } from '@ionic/react-router';
import { Observer } from 'mobx-react-lite';
import { useStores } from '../../stores';
import { Menu } from './';
import { Routes } from 'pages/Routes';
import { Modals } from './Modals';

export const Layout: React.FC<{ history: History }> = ({ history }) => {
    const { loaderStore, toastStore, alertStore } = useStores();

    return (
        <IonApp className="dark wrapper">
            <IonReactRouter history={history}>
                <IonSplitPane contentId="main">
                    <Menu />
                    <Routes />
                    <Observer>
                        {() => {
                            return (
                                <>
                                    <IonLoading isOpen={loaderStore.loading} message={loaderStore.loadingMessage} />
                                    {!!toastStore.toast && <IonToast {...toastStore.toast} />}
                                    {!!alertStore.alert && <IonAlert {...alertStore.alert} />}
                                </>
                            );
                        }}
                    </Observer>
                </IonSplitPane>
            </IonReactRouter>
            <Modals />
        </IonApp>
    );
};
