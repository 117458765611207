import { IonItem, IonLabel, IonText } from '@ionic/react';
import { UnauthenticatedPage } from '@/Layout';
import { EmailVerificationPendingForm } from './EmailVerificationPendingForm';
import { usePromiseHandler, useQueryParams } from 'utils';
import { useStores } from 'stores';

export const EmailVerificationPending: React.FC = () => {
    const { authStore } = useStores();
    const qs = useQueryParams();

    const tryContinue = usePromiseHandler(authStore.checkIfEmailVerified, {
        success: () => ({
            type: 'toast',
            header: 'Success',
            message: 'Your email address has been verified.',
            redirect: '/tour',
        }),
        error: {
            type: 'alert',
            header: 'Email Not Verified',
            message: 'You must verify your email before continuing. Please check your email or request a new verification.',
        },
    });

    return (
        <UnauthenticatedPage image="email-verification.png">
            <IonText color="secondary">
                <h3>We sent you an email to confirm your account. Please check your inbox.</h3>
            </IonText>
            <p>The link will expire in 24 hours. If you did not receive the email you can request a new verification link.</p>
            <EmailVerificationPendingForm email={qs.get('email') || ''} />
            <IonItem lines="none" className="ion-text-center" onClick={tryContinue}>
                <IonLabel color="secondary">Continue</IonLabel>
            </IonItem>
        </UnauthenticatedPage>
    );
};
