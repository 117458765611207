import { action, makeObservable, observable } from 'mobx';
import { ModalStore } from './ModalStore';
import type { Obj, ModalOptions } from './types';

export class Modal<P extends Obj = Obj> {
    readonly id!: string;
    readonly store: ModalStore;

    readonly options: ModalOptions<P>;

    @observable hidden!: boolean;
    @observable title!: string;
    @observable content: React.ReactNode;

    constructor(store: ModalStore, options: ModalOptions) {
        makeObservable(this);
        this.store = store;
        this.options = options;
        Object.assign(
            this,
            {
                hidden: false,
            },
            options
        );
    }

    @action.bound
    close(): void {
        this.store.close(this.id);
    }

    @action.bound
    hide(): void {
        this.store.hide(this.id);
    }

    @action.bound
    show(): void {
        this.store.show(this.id);
    }

    @action.bound
    update(options: Partial<Omit<ModalOptions, 'id' | 'type'>>): void {
        this.store.update(this.id, options);
    }
}
