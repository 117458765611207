import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import { CheckboxChangeEventDetail } from '@ionic/core';
import React, { useCallback, useMemo } from 'react';
import { ControllerRenderProps, FieldValues, Path, PathValue } from 'react-hook-form';
import { titleCase } from 'utils';
import { Field, FieldProps } from './Field';
import { FieldError } from './FieldError';

export interface CheckboxGroupProps<V extends FieldValues> extends Omit<FieldProps<V>, 'render'> {
    label?: string;
    choices: CheckboxChoice<V>[];
    checkboxProps?: Omit<React.ComponentProps<typeof IonCheckbox>, 'value' | 'checked'>;
    choiceLabelProps?: React.ComponentProps<typeof IonLabel>;
}

export type CheckboxChoiceValue<V extends FieldValues> = PathValue<V, Path<V>>;

export type CheckboxChoice<V extends FieldValues> = {
    value: CheckboxChoiceValue<V>;
    label?: React.ReactNode;
    description?: string;
    labelProps?: React.ComponentProps<typeof IonLabel>;
    checkboxProps?: Omit<React.ComponentProps<typeof IonCheckbox>, 'name' | 'value' | 'slot' | 'checked' | 'onIonChange'>;
};

export function CheckboxGroup<V extends FieldValues>(props: CheckboxGroupProps<V>): React.ReactElement {
    const { name, label, choices, required, rules, defaultValue, checkboxProps, choiceLabelProps } = props;
    const resolvedLabel = label || titleCase(name);
    return (
        <Field<V>
            name={name}
            required={required}
            defaultValue={defaultValue}
            rules={rules}
            render={(field, error) => (
                <>
                    <CheckboxChoices
                        field={field}
                        choices={choices}
                        labelProps={choiceLabelProps}
                        checkboxProps={{
                            ...checkboxProps,
                            name,
                        }}
                    />
                    <FieldError label={resolvedLabel} error={error} rules={rules} />
                </>
            )}
        />
    );
}

export type CheckboxChoicesProps<V extends FieldValues> = {
    field: ControllerRenderProps<V, Path<V>>;
    choices: CheckboxChoice<V>[];
    checkboxProps: Omit<React.ComponentProps<typeof IonCheckbox>, 'value' | 'checked' | 'onIonChange'>;
    labelProps?: React.ComponentProps<typeof IonLabel>;
};

export function CheckboxChoices<V extends FieldValues>(props: CheckboxChoicesProps<V>): React.ReactElement {
    const { field, choices, checkboxProps, labelProps } = props;
    const { value: valueOrValues, onChange } = field;

    const values = useMemo<CheckboxChoiceValue<V>[]>(
        () => (Array.isArray(valueOrValues) ? valueOrValues : valueOrValues !== undefined ? [valueOrValues as CheckboxChoiceValue<V>] : []),
        [valueOrValues]
    );

    const onIonChange = useCallback<(event: CustomEvent<CheckboxChangeEventDetail>) => void>(
        (e) => {
            onChange(e.detail.checked ? [...values, choices[e.detail.value].value] : values.filter((v) => v !== choices[e.detail.value].value));
        },
        [values, onChange, choices]
    );

    return (
        <>
            {choices.map((choice, i) => {
                const { label, description, labelProps: choiceLabelProps, checkboxProps: choiceCheckboxProps } = choice;
                return (
                    <CheckboxChoiceItem
                        key={i}
                        value={String(i)}
                        checked={values.includes(choices[i].value)}
                        label={label || titleCase(String(choices[i].value))}
                        description={description}
                        labelProps={{ ...labelProps, ...choiceLabelProps }}
                        onIonChange={onIonChange}
                        {...checkboxProps}
                        {...choiceCheckboxProps}
                    />
                );
            })}
        </>
    );
}

export function CheckboxChoiceItem(
    props: React.ComponentProps<typeof IonCheckbox> & { labelProps?: React.ComponentProps<typeof IonLabel>; label: React.ReactNode; description?: string }
): React.ReactElement {
    const { label, description, labelProps, checked, ...checkboxProps } = props;
    return (
        <IonItem lines="full" className={`uri-checkbox-item ${checked ? 'checked' : ''} ${description ? 'with-description' : ''}`}>
            <IonCheckbox checked={checked} {...checkboxProps} />
            <IonLabel className="ion-text-wrap" {...labelProps}>
                {description ? (
                    <>
                        <h3>{label}</h3>
                        <p>{description}</p>
                    </>
                ) : (
                    <>{label}</>
                )}
            </IonLabel>
        </IonItem>
    );
}

export type CheckboxProps<V extends FieldValues> = Omit<FieldProps<V>, 'render' | 'defaultValue'> & {
    defaultChecked?: boolean;
    label?: string;
    labelProps?: React.ComponentProps<typeof IonLabel>;
    description?: string;
} & Omit<React.ComponentProps<typeof IonCheckbox>, 'onIonChange' | 'onIonBlur' | 'value'>;

export function Checkbox<V extends FieldValues>(props: CheckboxProps<V>): React.ReactElement {
    const { name, label, required = false, rules, defaultChecked = false, ...checkboxProps } = props;
    const resolvedLabel = label || titleCase(name);

    return (
        <Field
            name={name}
            required={required}
            defaultValue={defaultChecked}
            rules={rules}
            render={({ onChange, onBlur, value }, error) => {
                return (
                    <>
                        <CheckboxChoiceItem
                            name={name}
                            label={resolvedLabel}
                            checked={value === true}
                            onIonChange={({ detail }) => onChange(detail.checked)}
                            onIonBlur={onBlur}
                            {...checkboxProps}
                        />
                        <FieldError label={resolvedLabel} error={error} rules={rules} />
                    </>
                );
            }}
        />
    );
}
