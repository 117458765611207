import { StudyApplication } from '../../stores/ParticipantStore/types';

export interface Profile {
    id: number;
    name: string;
    description: string;
    createdAt: Date;
    updatedAt: Date;
}

export const studyTypes = ['ONE_ONE_INTERVIEW', 'DYAD_INTERVIEW', 'FOCUS_GROUP', 'LIVE_GAMING', 'UNMODERATED', 'WORKSHOP', 'DIARY', 'SURVEY', 'OTHER'] as const;
export type StudyType = (typeof studyTypes)[number];
export type StudyParticipantStatus = 'SELECTED' | 'SCHEDULED' | 'PARTICIPATED' | 'PAID' | 'CLIENT_CANCELED' | 'PARTICIPANT_CANCELED' | 'NO_SHOW';

export interface Study {
    id: number;
    status: 'DRAFT' | 'ACTIVE' | 'PAUSED' | 'COMPLETED' | 'CANCELED' | 'DECLINED' | 'ARCHIVED';
    name: string;
    profileId: number;
    type: StudyType;
    qualtricsSurveyId: string;
    gratuityMin?: number;
    gratuityMax?: number;
    headline: string;
    location: string;
    additionalDetails?: string;
    description: string;
    requirements: string;
    featured: boolean;
    beginDate: Date;
    endDate: Date;
    lengthMinMinutes: number;
    lengthMaxMinutes: number;
    studyParticipants: {
        id: number;
        group: {
            name: string;
        };
        status: 'SCHEDULED' | 'PARTICIPATED' | 'PAID';
        gratuity: number;
    }[];
}

export type StudyInfo = StudyApplication['study'] & {
    status: 'DRAFT' | 'ACTIVE' | 'PAUSED' | 'COMPLETED' | 'CANCELED' | 'DECLINED' | 'ARCHIVED';
    description: string;
    requirements: string;
    headline: string;
    additionalDetails?: string;
    gratuityMin?: number;
    gratuityMax?: number;
    featured: boolean;
    studyParticipants: {
        id: number;
        group: {
            name: string;
        };
        status: 'SCHEDULED' | 'PARTICIPATED' | 'PAID';
        gratuity: number;
    }[];
    gratuity?: number;
};
