import { IonRouterOutlet } from '@ionic/react';
import { Profile } from './profile/Profile';
import { Personal } from './profile/personal/Personal';
import { StudyPreferences } from './profile/studyPreferences/StudyPreferences';
import { EmailVerificationPending } from './auth/email-verification/EmailVerificationPending';
import { RequestPasswordReset } from './auth/password-reset/RequestPasswordReset';
import { CompletePasswordReset } from './auth/password-reset/CompletePasswordReset';
import { Studies } from './studies/Studies';
import { StudyDetails } from './studies/details/StudyDetails';
import { EmailVerificationError } from './auth/email-verification/EmailVerificationError';
import { Reception } from './auth/reception/Reception';
import { Error } from './Error';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthenticatedRoute } from '@/Routes';
import { Welcome } from './welcome/Welcome';
import { Tour } from './tour/Tour';
import { CommunicationPreferences } from './profile/communication-preferences/CommunicationPreferences';
import { ErrorOptedOut } from './auth/error/ErrorOptedOut';
import { ErrorAccountLocked } from './auth/error/ErrorAccountLocked';
import { StudyHistory } from './profile/history/StudyHistory';
import { ExternalRedirect } from '@/Routes/ExternalRedirect';
import { useStores } from 'stores';
import { config } from '../config';

const SurveyRedirect: React.FC = () => {
    const { routerStore } = useStores();
    return <ExternalRedirect to={`${config.surveyAppBaseUrl}${routerStore.location?.pathname}`} />;
};

export const Routes: React.FC = () => {
    return (
        <IonRouterOutlet id="main">
            <Switch>
                <Route path="/login">
                    <Redirect to={{ pathname: '/welcome', search: '?step=login' }} />
                </Route>

                <Route path="/register">
                    <Redirect to={{ pathname: '/welcome', search: '?step=register' }} />
                </Route>

                <Route path="/reception">
                    <Reception />
                </Route>

                <Route path="/opted-out">
                    <ErrorOptedOut />
                </Route>

                <Route path="/account-locked">
                    <ErrorAccountLocked />
                </Route>

                <Route path="/email-verification/pending">
                    <EmailVerificationPending />
                </Route>

                <Route path="/email-verification/error">
                    <EmailVerificationError />
                </Route>

                <Route path="/password-reset/request">
                    <RequestPasswordReset />
                </Route>
                <Route path="/password-reset/complete">
                    <CompletePasswordReset />
                </Route>

                <AuthenticatedRoute exact path="/profile">
                    <Profile />
                </AuthenticatedRoute>

                <AuthenticatedRoute path="/profile/personal">
                    <Personal />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/profile/communications/">
                    <CommunicationPreferences />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/profile/history">
                    <StudyHistory />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/profile/study-preferences">
                    <StudyPreferences />
                </AuthenticatedRoute>

                <Route path="/studies">
                    <Studies />
                    <Route path="/studies/:studyId">
                        <StudyDetails />
                    </Route>
                </Route>

                <AuthenticatedRoute path="/survey/:surveyId/:respondentId">
                    <SurveyRedirect />
                </AuthenticatedRoute>

                <Route exact path="/tour">
                    <Tour />
                </Route>

                <Route exact path="/welcome">
                    <Welcome />
                </Route>

                <Route>
                    <Error />
                </Route>
            </Switch>
        </IonRouterOutlet>
    );
};
