import { configure } from 'mobx';
import { createBrowserHistory } from 'history';
import { rootStoreInstance, rootStoresContext } from './stores';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
import './theme/components.css';
import { Layout } from './components/Layout/Layout';
import { Observer } from 'mobx-react-lite';
import { Preloader } from '@/Layout';

// this makes changing observable state outside of actions
// strictly forbidden which encourages a more uni-directional
// data flow and prevents common foot guns
configure({
    enforceActions: 'always',
});

const history = rootStoreInstance.stores.routerStore.observeHistory(createBrowserHistory());

export const App: React.FC = () => {
    return (
        <rootStoresContext.Provider value={rootStoreInstance.stores}>
            <Observer>
                {() => {
                    const { loaderStore } = rootStoreInstance.stores;
                    return loaderStore.getLoadStatus('preload') ? <Preloader /> : <Layout history={history} />;
                }}
            </Observer>
        </rootStoresContext.Provider>
    );
};
