import { makeObservable, action, reaction } from 'mobx';
import { BaseStore } from '../BaseStore/BaseStore';
import { RootStores } from 'stores';
import { RootServices } from 'services';
import { Participant, StudyApplication } from './types';
import { lazyObservable } from 'utils';

export class ParticipantStore extends BaseStore {
    readonly participant = lazyObservable<Participant>((sink) =>
        this.apiService.apis.project.get<Participant>(`participant`).then(({ data }) => {
            sink(data);
        })
    );

    readonly studyApplications = lazyObservable<StudyApplication[]>((sink) => {
        if (!this.authStore.isAuthenticated) return [];
        return this.apiService.apis.project.get<StudyApplication[]>('participant/study-applications').then(({ data }) => {
            sink(data);
        });
    }, []);

    constructor() {
        super();
        makeObservable(this);
    }

    onInitialized(stores: RootStores, services: RootServices): void {
        super.onInitialized(stores, services);

        reaction(
            () => this.authStore.isAuthenticated,
            (authenticated) => {
                if (authenticated) {
                    this.studyApplications.reload();
                } else {
                    this.participant.reset();
                }
            }
        );
    }

    @action.bound
    async updateParticipant(participant: Partial<Participant>): Promise<void> {
        const { data: updatedParticipant } = await this.apiService.apis.project.patch<Participant>(`participant`, participant);
        this.participant.set(updatedParticipant);
    }
}
