import { IonRouterContext, IonRouterContextState } from '@ionic/react';
import { useCallback, useContext } from 'react';
import { ApiError } from 'services/ApiService/ApiError';
import { useStores } from 'stores';
import { Alert } from 'stores/AlertStore/AlertStore';
import { Toast } from 'stores/ToastStore/ToastStore';

export type PromiseHandlerType = 'toast' | 'alert' | 'custom';

export type PromiseHandlerToast = Exclude<Toast, string> & {
    type: 'toast';
};

export type PromiseHandlerAlert = Exclude<Alert, string> & {
    type: 'alert';
};

export type PromiseHandlerCustom = {
    type: 'custom';
};

export type PromiseHandler = (PromiseHandlerToast | PromiseHandlerAlert | PromiseHandlerCustom) & {
    redirect?: string | Parameters<IonRouterContextState['push']>;
    handler?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PromiseHandlers<R, P extends (...args: any[]) => Promise<R>> = {
    pending?: PromiseHandler | ((...args: Parameters<P>) => PromiseHandler | undefined | false);
    success?: PromiseHandler | ((resp: R, ...args: Parameters<P>) => PromiseHandler | undefined | false);
    error?: PromiseHandler | ((error: Error, ...args: Parameters<P>) => PromiseHandler | undefined | false);
    done?: PromiseHandler | ((...args: Parameters<P>) => PromiseHandler | undefined | false);
    errorCodes?: Record<number | string, PromiseHandler | ((error: Error, ...args: Parameters<P>) => PromiseHandler | undefined | false)>;
};

export type PromiseHandlerOptions = {
    loadingMessage?: string;
    rethrowErrors?: boolean;
    bindContext?: unknown;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePromiseHandler = <R, P extends (...args: any[]) => Promise<R>>(
    promise: P,
    handlers: PromiseHandlers<R, P>,
    options: PromiseHandlerOptions = {}
): ((...args: Parameters<P>) => Promise<void>) => {
    const { toastStore, alertStore, loaderStore } = useStores();
    const router = useContext(IonRouterContext);

    return useCallback(
        async (...args) => {
            const runHandler = (type: 'success' | 'error' | 'info', handler: PromiseHandler) => {
                if (handler.handler) {
                    handler.handler();
                }

                if (handler.type === 'alert') {
                    alertStore.show(type, handler);
                } else if (handler.type === 'toast') {
                    toastStore.show(type, handler);
                }

                if (handler.redirect) {
                    if (typeof handler.redirect === 'string') {
                        if (handler.redirect === 'back') {
                            router.back();
                        } else {
                            router.push(handler.redirect, 'root');
                        }
                    } else {
                        router.push(...handler.redirect);
                    }
                }
            };

            const { bindContext, rethrowErrors, loadingMessage } = options;

            const boundPromise = bindContext ? promise.bind(bindContext) : promise;

            const { pending: pendingHandler, success: successHandler, error: errorHandler, done: doneHandler, errorCodes: errorCodeHandlers = {} } = handlers;

            if (loadingMessage) {
                loaderStore.loadBegin('promise-handler', { message: loadingMessage });
            }

            if (pendingHandler) {
                const handler = typeof pendingHandler === 'function' ? pendingHandler(...args) : pendingHandler;
                if (handler) {
                    runHandler('info', handler);
                }
            }

            return boundPromise(...args)
                .then((result) => {
                    if (successHandler) {
                        const handler = typeof successHandler === 'function' ? successHandler(result, ...args) : successHandler;
                        if (handler) {
                            runHandler('success', handler);
                        }
                    }
                })
                .catch((e) => {
                    if (e instanceof ApiError) {
                        const code = e.errorCode || e.statusCode.toString();
                        const errorCodeHandlerKey = Object.keys(errorCodeHandlers).find((match) => {
                            return code.match(new RegExp(match));
                        });

                        if (errorCodeHandlerKey) {
                            const errorCodeHandler = errorCodeHandlers[errorCodeHandlerKey];
                            const handler = typeof errorCodeHandler === 'function' ? errorCodeHandler(e, ...args) : errorCodeHandler;
                            if (handler) {
                                runHandler('error', handler);
                                if (rethrowErrors) {
                                    throw e;
                                }
                                return;
                            }
                        }
                    }

                    if (errorHandler) {
                        const handler = typeof errorHandler === 'function' ? errorHandler(e, ...args) : errorHandler;
                        if (handler) {
                            runHandler('error', handler);
                            if (rethrowErrors) {
                                throw e;
                            }
                            return;
                        }
                    }

                    throw e;
                })
                .finally(() => {
                    if (loadingMessage) {
                        loaderStore.loadEnd('promise-handler');
                    }
                    if (doneHandler) {
                        const handler = typeof doneHandler === 'function' ? doneHandler(...args) : doneHandler;
                        if (handler) {
                            runHandler('info', handler);
                        }
                    }
                });
        },
        [options, toastStore, alertStore, loaderStore, handlers, promise, router]
    );
};
