import { Form, InputEmail } from '@/Form';
import { IonList } from '@ionic/react';
import { useCallback } from 'react';
import { useStores } from 'stores';
import { usePromiseHandler } from 'utils';

type EmailVerificationErrorFormData = {
    email: string;
};

export const EmailVerificationErrorForm: React.FC<{ email?: string }> = ({ email }) => {
    const { authStore } = useStores();

    const onSubmit = useCallback<(data: EmailVerificationErrorFormData) => Promise<void>>(
        ({ email }) => authStore.requestEmailVerification(email),
        [authStore]
    );

    const handleSubmit = usePromiseHandler(onSubmit, {
        success: {
            type: 'toast',
            header: 'Please check your inbox.',
            message: 'A new verification email has been sent. Older verification links will no longer work.',
        },
        errorCodes: {
            404: {
                type: 'alert',
                header: 'Account Not Found',
                message: 'Account with provided email does not exist.',
            },
            409: {
                type: 'toast',
                color: 'primary',
                header: 'Email Already Verified',
                message: 'Please log in.',
                duration: 4000,
                redirect: '/login',
            },
            410: {
                type: 'alert',
                header: 'Account Deactivated',
                message: 'Please contact support.',
            },
        },
    });

    return (
        <Form handleSubmit={handleSubmit} submitButton="Resend New Verification Link">
            <IonList>
                <InputEmail name="email" defaultValue={email} autoFocus />
            </IonList>
        </Form>
    );
};
