import { AuthenticatedPage } from '@/Layout';
import { IonList } from '@ionic/react';
import { ChangeEmail } from './ChangeEmail';
import { ChangeName } from './ChangeName';
import { ChangePhone } from './ChangePhone';

export const Personal: React.FC = () => {
    return (
        <AuthenticatedPage title="Personal Information" backButtonHref="/profile" backButtonText="Profile">
            <IonList>
                <ChangeName />
                <ChangePhone />
                <ChangeEmail />
            </IonList>
        </AuthenticatedPage>
    );
};
