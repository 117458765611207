import { Form, InputEmail } from '@/Form';
import { IonList } from '@ionic/react';
import { useCallback } from 'react';
import { useStores } from 'stores';
import { usePromiseHandler } from 'utils';

type EmailVerificationPendingFormData = {
    email?: string;
};

export const EmailVerificationPendingForm: React.FC<{ email?: string }> = ({ email }) => {
    const { authStore } = useStores();

    const onSubmit = useCallback<(data: EmailVerificationPendingFormData) => Promise<void>>(
        ({ email: formEmail }) => authStore.requestEmailVerification(formEmail || email || ''),
        [authStore, email]
    );

    const handleSubmit = usePromiseHandler(onSubmit, {
        success: {
            type: 'toast',
            header: 'Please check your inbox.',
            message: 'A new verification email has been sent. Older verification links will no longer work.',
        },
        errorCodes: {
            404: {
                type: 'alert',
                header: 'Account Not Found',
                message: 'Account with provided email does not exist.',
            },
            409: {
                type: 'alert',
                header: 'Account Already Verified',
                message: 'This account has already been verified.',
                redirect: '/',
            },
            410: {
                type: 'alert',
                header: 'Account Deactivated',
                message: 'Please contact support.',
            },
        },
    });

    return (
        <Form handleSubmit={handleSubmit} submitButton="Request New Link">
            <IonList>{!email && <InputEmail name="email" autoFocus />}</IonList>
        </Form>
    );
};
