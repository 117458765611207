export const arrayGroupBy = <P extends keyof T, T = Record<string, unknown>>(
    arr: T[],
    prop: P
): T[P] extends string | number | symbol | undefined | null ? Record<NonNullable<T[P]>, T[]> : never => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return arr.reduce((obj, item) => {
        if (item[prop] !== undefined && item[prop] !== null) {
            if (!obj[item[prop]]) {
                obj[item[prop]] = [];
            }
            obj[item[prop]].push(item);
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return obj;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any);
};
