import { Controller, ControllerRenderProps, FieldError, FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';

export type ControllerRules = React.ComponentProps<typeof Controller>['rules'];

export interface FieldProps<V extends FieldValues> {
    name: Path<V>;
    render: (field: ControllerRenderProps<V, Path<V>>, error: FieldError | undefined) => React.ReactNode;
    required?: boolean;
    defaultValue?: PathValue<V, Path<V>>;
    rules?: ControllerRules;
}

export function Field<V extends FieldValues>(props: FieldProps<V>): React.ReactElement {
    const { control } = useFormContext<V>();
    const { render, name, required = true, defaultValue, rules = {} } = props;

    if (required && rules.required === undefined) {
        rules.required = true;
    }

    return (
        <Controller<V>
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field, formState: { errors } }) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return <>{render(field, (errors as any)[field.name as any]) as FieldError | undefined}</>;
            }}
        />
    );
}
