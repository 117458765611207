import { InlineEdit, InputPhone } from '@/Form';
import { useOtpModal } from '@/Misc';
import { Observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useStores } from 'stores';
import { usePromiseHandler } from 'utils';

export const ChangePhone: React.FC = () => {
    const { authStore, appStore, analyticsStore } = useStores();
    const [openOtpModal] = useOtpModal({
        action: 'change_phone',
        handleSubmit: usePromiseHandler(({ phone, otp }) => authStore.changePhone(phone, otp), {
            success: {
                type: 'toast',
                header: 'Success',
                message: 'Your phone number was changed successfully. Please login to continue.',
                redirect: '/logout',
            },
            errorCodes: {
                409: {
                    type: 'alert',
                    header: 'Phone In Use',
                    message: 'This phone number is associated with another account. Please try a new number or contact support for assistance.',
                },
            },
        }),
    });

    const handleOtpSend = usePromiseHandler(authStore.sendPhoneOtp, {
        success: (_, phone) => ({
            type: 'custom',
            handler: () => {
                openOtpModal(phone);
            },
        }),
        error: {
            type: 'alert',
            header: 'Failed Sending Code',
            message: 'We were unable to send the code to the number you provided. Please try a different number or contact support for help.',
        },
        errorCodes: {
            PHONE_NOT_MOBILE: {
                type: 'alert',
                header: 'Invalid Number',
                message: 'Phone number is not a mobile number.',
            },
            COUNTRY_NOT_SUPPORTED: (_, phone) => ({
                type: 'alert',
                header: 'Unsupported Country',
                message: 'Unfortunately the country you selected is not currently supported.',
                handler: () => {
                    const country = appStore.countries.value().find((country) => phone.startsWith(country.dialCode));
                    if (country) {
                        analyticsStore.triggerEvent({ type: 'participant-app.unsupported-country', data: { countryCode: country.isoCode } });
                    }
                },
            }),
            429: {
                type: 'alert',
                message: 'We are experiencing higher than usual activity. Please wait a few minutes and try again.',
            },
        },
    });

    const handleSave = useCallback(
        async ({ phone }: { phone: string }) => {
            return handleOtpSend(phone, 'change_phone');
        },
        [handleOtpSend]
    );

    return (
        <Observer>
            {() => (
                <InlineEdit<{ phone: string }, void> label="Phone" value={authStore.user?.phone || ''} saveButtonText="Change Phone" handleSave={handleSave}>
                    <InputPhone name="phone" formattedInputName="phoneFormatted" />
                </InlineEdit>
            )}
        </Observer>
    );
};
