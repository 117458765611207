import { StudyInfo, StudyParticipantStatus, StudyType } from 'stores/StudyStore/types';
//
// functions in this file will likely end up in a model
// at some point if/when we port over the model instance
// tracking code from admin app

export const getStudyImage = (profileId: number): string => {
    switch (profileId) {
        case 1:
            return 'consumer';
        case 2:
            return 'gamer';
        case 3:
            return 'information-worker';
        case 4:
            return 'accessibility';
        case 5:
            return 'technology-professional';
        case 6:
            return 'traveler';
        default:
            return 'miscellaneous';
    }
};
export const getStudyDisplayGratuity = (study: StudyInfo): string => {
    const { gratuityMin, gratuityMax } = study;
    if (gratuityMin === undefined) return '$$$';
    return gratuityMin === gratuityMax ? `$${gratuityMin}` : `$${gratuityMin}-${gratuityMax}`;
};

export const getStudyDisplayType = (type: StudyType): string => {
    const types: Record<StudyType, string> = {
        ONE_ONE_INTERVIEW: '1:1 Interview',
        DYAD_INTERVIEW: '2:1 Interview',
        FOCUS_GROUP: 'Focus Group',
        LIVE_GAMING: 'Live Gaming',
        UNMODERATED: 'Self-Guided',
        WORKSHOP: 'Workshop',
        DIARY: 'Diary/Homework',
        SURVEY: 'Survey',
        OTHER: 'Miscellaneous',
    };
    return types[type];
};

export const getStudyParticipantStatus = (status: StudyParticipantStatus): string => {
    const types: Record<StudyParticipantStatus, string> = {
        SELECTED: 'Pending',
        SCHEDULED: 'Scheduled',
        PARTICIPATED: 'Participated (payment pending)',
        PAID: 'Paid',
        CLIENT_CANCELED: 'Pending',
        PARTICIPANT_CANCELED: 'Pending',
        NO_SHOW: 'Pending',
    };

    return types[status];
};
