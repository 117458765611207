import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { PortalHeader } from '@/Layout';

export interface ErrorPageProps {
    message?: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ message, children }) => {
    return (
        <IonPage id="error-page">
            <IonContent fullscreen>
                <PortalHeader />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        padding: '24px 12px',
                        textAlign: 'center',
                    }}
                >
                    <img src="assets/img/error.png" alt="Error" style={{ maxHeight: '35vh' }} />
                    <h2>{message || 'Oh no, something unexpected happened.'}</h2>
                    {children}
                </div>
            </IonContent>
        </IonPage>
    );
};
