import { AuthenticatedPage } from '@/Layout';
import { FormProps, Form } from '@/Form';
import { FieldValues } from 'react-hook-form';
import { IonButton } from '@ionic/react';
import { useRef } from 'react';
import { noop } from 'utils';

export interface InlineEditModalProps<V extends FieldValues, R> {
    title: string;
    saveButtonText?: string;
    handleClose: () => void;
    children?: React.ReactNode;
    formProps?: FormProps<V, R>;
}

export function InlineEditModal<V extends FieldValues, R>({
    children,
    title,
    saveButtonText = 'Save',
    handleClose,
    formProps,
}: InlineEditModalProps<V, R>): React.ReactElement {
    const formRef = useRef<HTMLFormElement>(null);

    if (!formProps) return <></>;

    const Footer = (
        <IonButton
            expand="block"
            className="ion-padding-horizontal"
            onClick={() => {
                formRef.current?.submit();
            }}
        >
            {saveButtonText}
        </IonButton>
    );

    return (
        <AuthenticatedPage title={title} secondaryAction="close" handleClose={handleClose} footer={Footer}>
            <Form ref={formRef} handleSuccess={handleClose} handleError={noop} {...formProps}>
                {children}
            </Form>
        </AuthenticatedPage>
    );
}
