import { makeObservable, action, computed, reaction, observable } from 'mobx';
import { Study, Profile, StudyInfo } from './types';
import { BaseStore } from '../BaseStore/BaseStore';
import { RootServices } from 'services';
import { RootStores } from 'stores';
import { lazyObservable } from 'utils';
import { StudyApplication } from '../../stores/ParticipantStore/types';
import { AxiosResponse } from 'axios';

export class StudyStore extends BaseStore {
    readonly profiles = lazyObservable<Profile[]>(
        (sink) =>
            this.apiService.apis.project.get<Profile[]>('profiles').then(({ data }) => {
                sink(data);
            }),
        []
    );

    @observable searchTerm = '';
    @observable profileFilters: number[] = [];
    @observable methodologyFilters: string[] = [];

    @action
    setSearchTerm(searchTerm: string | null | undefined): void {
        if (searchTerm && searchTerm.length > 0) {
            this.searchTerm = searchTerm;
        } else {
            this.searchTerm = '';
        }
    }

    @action
    setProfileFilter(checked: boolean, profileId: number): void {
        if (checked) {
            this.profileFilters.push(profileId);
        } else {
            this.profileFilters = this.profileFilters.filter((p) => p !== profileId);
        }
    }

    @action
    setMethodologyFilter(checked: boolean, methodology: string): void {
        if (checked) {
            this.methodologyFilters.push(methodology);
        } else {
            this.methodologyFilters = this.methodologyFilters.filter((m) => m !== methodology);
        }
    }

    @computed get profileIds(): number[] {
        return this.profiles.value().map((t) => t.id);
    }

    readonly activeStudies = lazyObservable<Study[]>(
        (sink) =>
            this.apiService.apis.project.get<Study[]>('studies').then(({ data }) => {
                sink(data);
            }),
        []
    );

    @computed get sortedStudies(): Study[] {
        let studies = this.activeStudies.value();
        let participantProfileIds: number[] | undefined = [];

        if (this.authStore.user) {
            studies = studies.filter(
                (s) =>
                    !this.participantStore.studyApplications
                        .value()
                        .filter((sa) => sa.respondentStatus === 'COMPLETED')
                        .map((sa) => sa.studyId)
                        .includes(s.id)
            );
            participantProfileIds = this.participantStore.participant.value()?.profileIds;
        }
        const sortStudies = (a: Study, b: Study) => {
            const aIsPreferred = participantProfileIds && participantProfileIds.includes(a.profileId);
            const bIsPreferred = participantProfileIds && participantProfileIds.includes(b.profileId);
            if (a.featured !== b.featured) {
                if (b.featured) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (aIsPreferred !== bIsPreferred) {
                if (bIsPreferred) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                return a.beginDate.getTime() - b.beginDate.getTime();
            }
        };

        studies = studies.slice().sort(sortStudies);

        if (this.searchTerm) {
            studies = studies.filter(
                (s) =>
                    s.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    s.headline.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    s.description.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    s.requirements.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    s.location.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        }
        if (this.profileFilters.length > 0) {
            studies = studies.filter((s) => this.profileFilters.includes(s.profileId));
        }

        if (this.methodologyFilters.length > 0) {
            studies = studies.filter((s) => this.methodologyFilters.includes(s.type));
        }

        return studies;
    }

    @computed get incompleteApplications(): StudyInfo[] {
        return this.participantStore.studyApplications
            .value()
            .filter(
                (sa) => sa.study.status === 'ACTIVE' && sa.respondentStatus !== 'COMPLETED' && this.activeStudies.value().some(({ id }) => id === sa.study.id)
            )
            .sort((a: StudyApplication, b: StudyApplication) => b.id - a.id)
            .map(({ study, studyParticipants, gratuityMin, gratuityMax }) => ({
                ...study,
                studyParticipants,
                gratuityMin,
                gratuityMax,
            }));
    }

    @computed get completedApplications(): StudyInfo[] {
        return this.participantStore.studyApplications
            .value()
            .filter((sa) => sa.respondentStatus === 'COMPLETED')
            .sort((a: StudyApplication, b: StudyApplication) => b.id - a.id)
            .map(({ study, studyParticipants, gratuityMin, gratuityMax }) => ({
                ...study,
                studyParticipants,
                gratuityMin,
                gratuityMax,
            }));
    }

    @computed get participatedStudies(): StudyInfo[] {
        return this.participantStore.studyApplications
            .value()
            .filter((sa) => sa.studyParticipants.length > 0)
            .sort((a: StudyApplication, b: StudyApplication) => b.id - a.id)
            .map(({ study, studyParticipants, gratuityMin, gratuityMax }) => ({
                ...study,
                studyParticipants,
                gratuityMin,
                gratuityMax,
            }));
    }

    constructor() {
        super();
        makeObservable(this);
    }

    onInitialized(stores: RootStores, services: RootServices): void {
        super.onInitialized(stores, services);

        // we need to reload the active studies anytime the authentication
        // status changes since the API endpoint returns a different payload
        // depending on whether you are logged in
        reaction(
            () => this.authStore.user,
            () => {
                this.activeStudies.reload();
                this.authStore.user && this.participantStore.participant.reload();
            }
        );
    }

    @action.bound
    async getStudy(id: string | number): Promise<Study> {
        const { data: study } = await this.apiService.apis.project.get<Study>(`studies/${id}`);
        return study;
    }

    @action.bound
    async apply(studyId: number): Promise<StudyApplication> {
        const { data: application } = await this.apiService.apis.project.post<StudyApplication, AxiosResponse<StudyApplication>, { studyId: number }>(
            `participant/study-applications`,
            { studyId }
        );
        return application;
    }
}
