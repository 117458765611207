import { IonList, IonItem, IonIcon, IonLabel, IonListHeader } from '@ionic/react';
import { chatbox, options, helpCircle, mail, person, fileTrayFull } from 'ionicons/icons';
import { AuthenticatedPage } from '@/Layout';

export const Profile: React.FC = () => {
    return (
        <AuthenticatedPage title="Profile" secondaryAction="menu" backButtonHref="/studies">
            <IonList>
                <IonListHeader>
                    <IonLabel>Profile</IonLabel>
                </IonListHeader>

                <IonItem routerLink="/profile/personal" detail={false} routerDirection="root">
                    <IonIcon slot="end" icon={person} color="primary" />
                    Personal Information
                </IonItem>
                <IonItem routerLink="/profile/history" detail={false} routerDirection="root">
                    <IonIcon slot="end" icon={fileTrayFull} color="primary" />
                    History
                </IonItem>
                <IonItem routerLink="/profile/study-preferences" detail={false} routerDirection="root">
                    <IonIcon slot="end" icon={options} color="primary" />
                    Study Preferences
                </IonItem>
                <IonItem routerLink="/profile/communications" detail={false} routerDirection="root">
                    <IonIcon slot="end" icon={chatbox} color="primary" />
                    Communication Preferences
                </IonItem>
                <IonListHeader>
                    <IonLabel>Support</IonLabel>
                </IonListHeader>

                <IonItem href="https://www.uriux.com/participant-portal/help-center/" target="_blank" rel="noreferrer" detail={false}>
                    <IonIcon slot="end" icon={helpCircle} color="primary" />
                    Help Center
                </IonItem>
                <IonItem href="mailto:portalsupport@uriux.com?subject=URI Participant Portal Feedback" target="_blank" rel="noreferrer" detail={false}>
                    <IonIcon slot="end" icon={mail} color="primary" />
                    Give Us Feedback
                </IonItem>

                <IonListHeader>
                    <IonLabel>Legal</IonLabel>
                </IonListHeader>

                <IonItem href="https://www.uriux.com/tos" target="_blank" rel="noreferrer" detail={false}>
                    Terms of Service
                </IonItem>
                <IonItem href="https://www.uriux.com/privacy" target="_blank" rel="noreferrer" detail={false}>
                    Privacy Policy
                </IonItem>
            </IonList>
        </AuthenticatedPage>
    );
};
