import { useStores } from 'stores';
import { useMountEffect } from './useMountEffect';

export const useUnauthenticatedPage = (): void => {
    const { authStore, routerStore } = useStores();

    useMountEffect(() => {
        // even if the authStore says the user is  authenticated, we need to make sure
        // the email and phone have been verified before considering the user logged in.
        if (authStore.isAuthenticated && authStore.user?.phone && authStore.user?.emailIsVerified) {
            routerStore.replace('/');
        } else if (authStore.user) {
            routerStore.replace(authStore.user.phone ? '/email-verification/pending' : 'welcome/?step=set-phone');
        }
    });
};
