import { UnauthenticatedPage } from '@/Layout';
import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useQueryParams } from 'utils';
import { LoginEmail } from './LoginEmail';
import { LoginPassword } from './LoginPassword';
import { RegisterForm } from './RegisterForm';
import { RegisterPhone } from './RegisterPhone';
import { WelcomeStart } from './WelcomeStart';
import { SetPhone } from './SetPhone';

export interface Progress {
    step?: 'start' | 'login' | 'register' | 'login-otp' | 'login-password' | 'register-form' | 'set-phone';
    email?: string;
    phone?: string;
    phoneOtp?: string;
}

export type SetProgress = Dispatch<SetStateAction<Progress>>;

export const Welcome: React.FC = () => {
    //useUnauthenticatedPage();

    const qs = useQueryParams();
    const [progress, setProgress] = useState<Progress>({});

    useEffect(() => {
        setProgress({
            step: (qs.get('step') as Progress['step']) || 'start',
            email: qs.get('email') || undefined,
            phone: qs.get('phone') || undefined,
        });
    }, [qs, setProgress]);

    const { step } = progress;

    return (
        <UnauthenticatedPage hideSupportLink>
            {step !== 'register-form' && (
                <>
                    <div className="uri-welcome-banner"></div>
                    <h1 style={{ textAlign: 'center' }}>Welcome</h1>
                </>
            )}
            {(step === 'start' || step === 'register') && (
                <p style={{ textAlign: 'center' }}>
                    Become a URI participant to apply for paid research studies.{' '}
                    <a href="https://www.uriux.com/participate/" target="_blank" rel="noreferrer">
                        Click here to learn more
                    </a>
                    .
                </p>
            )}
            {step === 'start' && <WelcomeStart progress={progress} setProgress={setProgress} />}
            {step === 'login' && <LoginEmail progress={progress} setProgress={setProgress} />}
            {step === 'login-password' && <LoginPassword progress={progress} setProgress={setProgress} />}

            {step === 'register' && <RegisterPhone progress={progress} setProgress={setProgress} />}
            {step === 'register-form' && <RegisterForm progress={progress} setProgress={setProgress} />}
            {(step === 'start' || step === 'register' || step === 'login') && (
                <p className="uri-support-link">
                    By registering, you agree to receive text messages from User Research International for the purposes of logging in. Message and data rates
                    may apply.
                </p>
            )}
            {step === 'set-phone' && <SetPhone progress={progress} setProgress={setProgress} />}
        </UnauthenticatedPage>
    );
};
