import { Form, InputOtp } from '@/Form';
import { PageImage } from '@/Generic';
import { IonButton, IonList } from '@ionic/react';
import { useCallback, useRef } from 'react';
import { useStores } from 'stores';
import { usePromiseHandler } from 'utils';

export type OtpFormProps = {
    codeLength: number;
    loadingMessage?: string;
    phone?: string;
    handleSubmit: (data: { otp: string }) => Promise<unknown>;
    handleClose: () => unknown;
    handleResend?: () => Promise<unknown>;
};

export const OtpForm: React.FC<OtpFormProps> = ({ codeLength, handleResend, handleClose, handleSubmit, loadingMessage = 'Verifying...', phone }) => {
    const formRef = useRef<HTMLFormElement>(null);

    const handleOtpSubmit = usePromiseHandler(handleSubmit, {
        success: {
            type: 'custom',
            handler: () => {
                handleClose();
            },
        },
        errorCodes: {
            422: {
                type: 'alert',
                header: 'Invalid or Expired Code',
                message: 'Please request a new verification code and try again.',
                handler: () => {
                    formRef.current?.reset();
                },
            },
        },
    });

    const handleCode = useCallback(() => {
        formRef.current?.submit();
    }, []);

    const handleOtpResend = useCallback(() => {
        formRef.current?.reset();
        if (handleResend) {
            handleResend();
        }
    }, [handleResend]);

    return (
        <div className="uri-modal-form">
            <PageImage image={'phone-otp-sent.png'} />
            {!phone || phone.includes('@') ? (
                <h2>Enter the code sent to the phone number associated with your account.</h2>
            ) : (
                <h2>Enter the code sent to {phone}.</h2>
            )}
            <Form handleSubmit={handleOtpSubmit} ref={formRef} loadingMessage={loadingMessage}>
                <IonList>
                    <InputOtp name="otp" codeLength={codeLength} handleCode={handleCode} autoFocus />
                </IonList>
                <br />
            </Form>
            {handleResend && (
                <IonButton color="primary" expand="block" onClick={handleOtpResend}>
                    Request New Code
                </IonButton>
            )}
            <IonButton fill="clear" size="small" expand="full" onClick={() => handleClose()}>
                Cancel
            </IonButton>
        </div>
    );
};

export type OtpModalConfig = {
    loadingMessage?: string;
    action: 'login' | 'register' | 'change_phone';
    handleSubmit: (data: { otp: string; phone: string }) => Promise<unknown>;
};

export const useOtpModal = (config: OtpModalConfig): [(phone: string) => void, () => void] => {
    const { authStore, modalStore } = useStores();

    const handleResend = usePromiseHandler(
        (phone: string) => authStore.sendPhoneOtp(phone, config.action),
        {
            success: {
                type: 'toast',
                message: 'A new code has been sent. Please enter it above to continue.',
            },
            error: {
                type: 'alert',
                header: 'Failed Sending Code',
                message: 'We were unable to send the code to the number you provided. Please try a different number or contact support for help.',
            },
            errorCodes: {
                PHONE_NOT_MOBILE: {
                    type: 'alert',
                    header: 'Invalid Number',
                    message: 'Phone number is not a mobile number.',
                },
                429: {
                    type: 'alert',
                    message: 'We are experiencing higher than usual activity. Please wait a few minutes and try again.',
                },
            },
        },
        { bindContext: authStore }
    );

    return [
        (phone) => {
            modalStore.open(
                { id: 'otp', component: OtpForm },
                {
                    phone,
                    codeLength: 6,
                    handleClose: () => modalStore.close('otp'),
                    handleResend: () => handleResend(phone),
                    handleSubmit: ({ otp }) => config.handleSubmit({ otp, phone }),
                    loadingMessage: config.loadingMessage,
                }
            );
        },
        () => modalStore.close('otp'),
    ];
};
