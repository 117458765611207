import { observable, action, _allowStateChanges, CreateObservableOptions } from 'mobx';

export interface ILazyObservable<T> {
    value(): T;
    reload(debounceMs?: number): Promise<T>;
    reset(): T;
    set(value: T): void;
    promise(): Promise<T>;
    loading: boolean;
}

export function lazyObservable<T>(fetch: (sink: (newValue: T) => void) => void): ILazyObservable<T | undefined>;
export function lazyObservable<T>(fetch: (sink: (newValue: T) => void) => void, initialValue: T, options?: CreateObservableOptions): ILazyObservable<T>;
/**
 * `lazyObservable` creates an observable around a `fetch` method that will not be invoked
 * until the observable is needed the first time.
 * The fetch method receives a `sink` callback which can be used to replace the
 * value value of the lazyObservable. It is allowed to call `sink` multiple times
 * to keep the lazyObservable up to date with some external resource.
 *
 * Note that it is the `value()` call itself which is being tracked by MobX,
 * so make sure that you don't dereference to early.
 *
 * @example
 * const userProfile = lazyObservable(
 *   sink => fetch("/myprofile").then(profile => sink(profile))
 * )
 *
 * // use the userProfile in a React component:
 * const Profile = observer(({ userProfile }) =>
 *   userProfile.value() === undefined
 *   ? <div>Loading user profile...</div>
 *   : <div>{userProfile.value().displayName}</div>
 * )
 *
 * // triggers reload the userProfile
 * userProfile.reload()
 *
 * @param {(sink: (newValue: T) => void) => void} fetch method that will be called the first time the value of this observable is accessed. The provided sink can be used to produce a new value, synchronously or asynchronously
 * @param {T} [initialValue=undefined] optional initialValue that will be returned from `value` as long as the `sink` has not been called at least once
 * @returns {{
 *     value(): T,
 *     reload(): T,
 *     reset(): T
 *     loading: boolean
 * }}
 */
export function lazyObservable<T>(
    fetch: (sink: (newValue: T) => void) => void,
    initialValue: T | undefined = undefined,
    options?: CreateObservableOptions
): ILazyObservable<T | undefined> {
    let started = false;
    const value = observable.box<T | undefined>(initialValue, options);
    const loading = observable.box(false);

    let promiseResolve: (value: T) => void, promiseReject: (value: unknown) => void;

    const promise = new Promise<T>(function (resolve, reject) {
        promiseResolve = resolve;
        promiseReject = reject;
    });

    const valueFnc = () => {
        if (!started) {
            started = true;
            _allowStateChanges(true, () => {
                loading.set(true);
            });
            try {
                fetch((newValue: T) => {
                    _allowStateChanges(true, () => {
                        value.set(newValue);
                        loading.set(false);
                        promiseResolve(value.get() as T);
                    });
                });
            } catch (e) {
                promiseReject(e);
            }
        }
        return value.get();
    };

    const resetFnc = action('lazyObservable-reset', () => {
        value.set(initialValue);
        return value.get();
    });

    const reloadFnc: () => Promise<T> = () => {
        //if (loading.get()) return promise;
        started = false;
        return promiseFnc();
    };

    const promiseFnc = () => {
        if (!started) {
            valueFnc();
        }
        return promise;
    };

    let reloadTimer: NodeJS.Timeout;

    return {
        value: valueFnc,
        promise: promiseFnc,
        reload: (debounceMs = 0) => {
            clearTimeout(reloadTimer);
            if (debounceMs) {
                reloadTimer = setTimeout(() => {
                    reloadFnc();
                }, debounceMs);
                return promiseFnc();
            } else {
                return reloadFnc();
            }
        },
        set: (newValue) => {
            value.set(newValue);
        },
        reset: () => {
            return resetFnc();
        },
        get loading() {
            return loading.get();
        },
    };
}
